import { useAppSelector } from '@/redux/counterSlice';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';

const useHasLiveTours = () => {
  const { data: session, status } = useSession();
  const { liveKey } = useAppSelector((state) => state.counter);

  const { data, isLoading } = useQuery<{
    tournamentIds: number[];
    status: boolean;
  }>({
    queryKey: ['Has Live Tournament', session?.secure, liveKey],
    queryFn: async () => {
      const { status, tournamentIds } = await nodeAPI(
        'GET',
        session?.secure,
        'play/hasLiveTournament',
      );

      return {
        status,
        tournamentIds,
      };
    },
    enabled: status == 'authenticated',
  });

  return {
    hasLive: data?.status,
    tournamentIds: data?.tournamentIds,
    isLoading,
  };
};

export default useHasLiveTours;
