import { cn } from '@nextui-org/react';
import Link from 'next/link';

import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaSquareXTwitter,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa6';

export const socialIcons = [
  {
    id: 1,
    href: 'https://www.facebook.com/GamerG.Esports/',
    icon: <FaFacebook />,
  },
  {
    id: 2,
    href: 'https://x.com/gamerg_official',
    icon: <FaSquareXTwitter />,
  },
  {
    id: 3,
    href: 'https://discord.gg/uSJkN95JxW',
    icon: <FaDiscord />,
  },
  {
    id: 4,
    href: 'https://instagram.com/gamerg.gg',
    icon: <FaInstagram />,
  },
  {
    id: 5,
    href: 'https://www.tiktok.com/@gamerg_gg',
    icon: <FaTiktok />,
  },

  {
    id: 6,
    href: 'https://youtube.com/@GamerG-Esports',
    icon: <FaYoutube />,
  },
];

const SocialMediaIcons = ({ className }: { className?: string }) => {
  return (
    <div className={cn('flex items-center gap-5 w-full', className)}>
      {socialIcons.map(({ id, href, icon }) => (
        <Link
          href={href}
          key={id}
          target="_blank"
          rel="noopener noreferrer"
          className="group cursor-pointer *:text-jacarta-300 *:h-5 *:w-5 *:transition-colors *:group-hover:text-accent *:dark:group-hover:text-white"
        >
          {icon}
        </Link>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
