import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';
import { useBalanceStore } from '../store/useBalance';

const useUpdateBalance = () => {
  const { setBalance } = useBalanceStore();
  const { data: session } = useSession();

  const updateBalance = async (amountToCheck?: number) => {
    const response = await nodeAPI(
      'POST',
      session?.secure,
      'extra/checkUserBalance',
      { amountToCheck },
    );

    if (response) setBalance(response);
    return true;
  };

  return { updateBalance };
};

export default useUpdateBalance;
