import { Button, cn, Image } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

export default function WhatsappButton({
  floating = false,
}: {
  floating?: boolean;
}) {
  const { t } = useTranslation();

  const whatsappNumber = '+962798213050';
  const whatsappURL = `https://wa.me/${whatsappNumber}`;

  return (
    <>
      <Button
        as={'a'}
        href={whatsappURL}
        target="_blank"
        rel="noopener noreferrer"
        radius={floating ? 'full' : 'lg'}
        className={cn(
          'hidden md:flex items-center bg-[#37C32F] text-white',
          floating && 'fixed bottom-5 end-4 z-[49] flex md:hidden',
        )}
      >
        <span>
          <Image
            src="/images/icons/whatsapp.png"
            width={32}
            height={32}
            alt="whatsapp"
            disableSkeleton
          />
        </span>
        <span className="hidden sm:inline-block">
          {t('Talk to us on WhatsApp')}
        </span>
      </Button>
    </>
  );
}
