import React, { useEffect, useState, useRef } from 'react';

import nodeAPI from '../../lib/nodeapi';
import cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { Select, SelectItem } from '@nextui-org/react';
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Button,
  Skeleton,
} from '@nextui-org/react';

const CurrencySwitcher = () => {
  const router = useRouter();
  const { pathname, asPath, query } = useRouter();
  const [currency, setCurrency] = useState(cookies.get('currency') || 'USD');

  const { data, isLoading } = useQuery({
    queryKey: ['getAllCurrencies'],
    queryFn: async () => await nodeAPI('GET', '', 'extra/getAllCurrencies', {}),
  });

  const handleSetCurrency = (currencyCode) => {
    cookies.set('currency', currencyCode.toString());
    setCurrency(currencyCode.toString());
    router.push({ pathname, query }, asPath).then();
  };

  return (
    <>
      {/* <!-- CURRENCY Select --> */}

      {data?.results ? (
        <Select
          onSelectionChange={(e) => {
            handleSetCurrency(e.currentKey);
          }}
          defaultSelectedKeys={[currency]}
          size="sm"
          className="flex-1 lg:hidden"
          radius="full"
          aria-label="currency-switcher"
        >
          {data?.results?.map((element) => (
            <SelectItem
              key={element.currency_code}
              value={element.currency_code}
              aria-label={element.currency_name ?? 'currency-filed'}
              // className="text-jacarta-50 "
            >
              {router.locale === 'en-US'
                ? element.currency_name
                : element.currency_name_ar}
            </SelectItem>
          ))}
        </Select>
      ) : null}

      {/* <!-- CURRENCY Menu --> */}

      <Dropdown
        classNames={{
          content: 'menu-gradient',
        }}
      >
        <DropdownTrigger className="hidden lg:flex">
          <Button
            size="sm"
            isIconOnly
            variant="faded"
            radius="full"
            className={'menu-icon-button text-[10px]'} // in global css
            aria-label="currency-switcher"
          >
            {currency}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="lang-switcher-menu"
          variant="flat"
          onAction={(e) => handleSetCurrency(e)}
          itemClasses={{
            base: [
              'text-jacarta-800 dark:text-jacarta-50',
              'data-[hover=true]:bg-jacarta-100',
              'dark:data-[hover=true]:bg-jacarta-700',
            ],
          }}
        >
          {data?.results?.map((element) => (
            <DropdownItem key={element.currency_code}>
              {router.locale === 'en-US'
                ? element.currency_name
                : element.currency_name_ar}
            </DropdownItem>
          ))}
          {isLoading && !data?.results ? (
            <>
              <Skeleton className="h-3 w-3/5 rounded-md " />
              <Skeleton className="h-3 w-2/5 rounded-md " />
              <Skeleton className="h-3 w-4/5 rounded-md " />
            </>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default CurrencySwitcher;
