import { useTranslation } from 'react-i18next';
import WinnerCard from '../dashboard/live/winnerCard';
import { GAMES } from '@/lib/games';
import { useMemo } from 'react';
import { LeaderboardUser } from '@/types/types';

const ScoreCard = ({
  userScore,
}: {
  userScore: LeaderboardUser & {
    gameId: string;
    pic_border: string;
    battlecard: string;
    battlecard_border: string;
  };
}) => {
  const { t } = useTranslation();

  const game = useMemo(
    () => GAMES.find(({ value }) => value == userScore.gameId)?.label ?? '',
    [userScore.gameId],
  );

  return (
    <div>
      <WinnerCard user={userScore as any} isSmall>
        <h3 className="text-lg font-display">{t(game)}</h3>
        <span className="text-5xl font-display font-black">
          {userScore?.ordinalRank}
        </span>
        <span className="font-semibold">
          {t('Score')}: {userScore?.score}
        </span>
      </WinnerCard>
    </div>
  );
};

export default ScoreCard;
