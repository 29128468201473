import { Image } from '@nextui-org/react';
import { CardTooltip } from './CardTooltip';
import { t } from 'i18next';
import { setCreditModal, useAppDispatch } from '@/redux/counterSlice';
import { useControlPanelStore } from '@/store/useControlPanelStore';

interface Props {
  balance: number;
}
export const GGCard = ({ balance }: Props) => {
  const dispatch = useAppDispatch();

  const { setIsOpen } = useControlPanelStore();

  return (
    <div className="flex gap-2 flex-col flex-1 ">
      <div className="flex items-center justify-between">
        <p
          className={`text-sm font-semibold text-GG-blue dark:text-GG-blue-light`}
        >
          {t('GG Credits')}
        </p>
        <CardTooltip
          text={t(
            `GG Points: You receive GG's Points daily for free. Use them to join tournaments or purchase gift cards!`,
          )}
        />
      </div>
      <div
        className={`rounded-md text-center  py-2 border text-GG-blue dark:text-GG-blue-light border-GG-blue dark:border-GG-blue-light px-4 w-full relative cursor-pointer`}
        onClick={() => {
          dispatch(setCreditModal(true));
          setIsOpen(false);
        }}
      >
        <p
          className={`text-md font-semibold text-GG-blue dark:text-GG-blue-light me-2`}
        >
          {balance}{' '}
        </p>
        <div className="self-end absolute top-2 end-1">
          <Image
            disableSkeleton
            src="/images/gg-credit/GG credits.png"
            className="w-6 h-6"
            alt="gg credit"
          />
        </div>
      </div>
    </div>
  );
};
