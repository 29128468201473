import cookies from 'js-cookie';
import { useMemo } from 'react';
import { useCurrencyStore } from '../store/useCurrencyStore';

const CurrencyField = ({ amount }) => {
  const { latestRate, digit } = useCurrencyStore();

  const converted = useMemo(() => {
    if (latestRate > 0 && amount > 0)
      return (Math.round(amount * latestRate * 10) / 10).toFixed(digit);

    return 0;
  }, [amount, latestRate, digit]);

  return (
    <>
      {converted} {cookies.get('currency')}
    </>
  );
};

export default CurrencyField;
