import { Image } from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

export default function Logo() {
  const { status } = useSession();
  return (
    <Link href={status === 'authenticated' ? '/dashboard' : '/'}>
      <Image
        src="/images/logo_white.png"
        alt="Gamerg.gg logo"
        className="hidden md:dark:block max-h-20 "
      />
      <Image
        src="/images/logo.png"
        alt="Gamerg.gg logo"
        className="hidden md:block dark:hidden max-h-20"
      />
      <Image
        src="/images/logo-icon.png"
        alt="Gamerg.gg logo"
        className="block md:hidden max-h-11"
      />
    </Link>
  );
}
