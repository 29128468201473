import { useEffect, useState } from 'react';
import nodeAPI from '../lib/nodeapi';
import { useSelector } from 'react-redux';
import { useSession } from 'next-auth/react';

const useCheckDisclamer = () => {
  const [isData, setData] = useState(null);
  const { checkAccountSet } = useSelector((state) => state.counter);
  const { data: session } = useSession();

  const checkDisclaimer = async () => {
    if (await session?.secure) {
      await nodeAPI('POST', session.secure, 'auth/accountSet').then(
        (response) => {
          setData(response);
          return isData;
        },
      );
    }
  };

  useEffect(() => {
    checkDisclaimer();
  }, [session, checkAccountSet]);

  return { isData };
};

export default useCheckDisclamer;
