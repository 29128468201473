export default async function nodeAPI(
  method,
  secure,
  query,
  variables = {},
  multipart = false,
) {
  const dev = process.env.NODE_ENV !== 'production';
  // {}
  // const server = dev ? 'https://127.0.0.1:3010' : NEXT_PUBLIC_NODE_API;

  const headers = {
    secure: secure,
  };

  if (multipart) {
    delete headers['Content-Type'];
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  const res = await fetch(process.env.NEXT_PUBLIC_NODE_API + query, {
    method: method,
    headers: headers,
    ...(method == 'POST' && {
      body: multipart
        ? variables.body
        : new URLSearchParams(variables).toString(),
    }),
  });

  const json = await res.json();
  if (json.errors) {
    // console.error(json.errors);
    throw new Error('Failed to fetch API');
  }

  return json;
}
