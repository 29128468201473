import { create } from 'zustand';

interface State {
  tournamentId?: number;
  setTournamentId: (tournamentId: State['tournamentId']) => void;
  bracketId?: number;
  setBracketId: (bracketId: State['bracketId']) => void;
}

export const useLiveStore = create<State>()((set) => ({
  tournamentId: undefined,
  setTournamentId: (tournamentId) => set(() => ({ tournamentId })),
  bracketId: undefined,
  setBracketId: (bracketId) => set(() => ({ bracketId })),
}));
