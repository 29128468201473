import {
  setCreditSuccessModal,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice';
import { modalStyle, primaryButton } from '@/theme/nextUI';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  cn,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useEffect } from 'react';
import { DayCard, DayLongCard } from '../layout/cards/DayCard';

function SuccessClaimModal() {
  const dispatch = useAppDispatch();

  const { creditSuccessModalIsOpen: isOpen, creditSuccessModalDay: day } =
    useAppSelector((state) => state.counter);

  const closeModal = async () => {
    dispatch(setCreditSuccessModal({ isOpen: false, day: null }));
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="sm"
      placement="center"
      onClose={closeModal}
      classNames={{
        ...modalStyle,
        header: 'px-5 pb-0 border-none',
      }}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Daily Rewards')}</h5>
        </ModalHeader>
        <ModalBody className="flex flex-col items-center gap-4">
          {day && day < 5 ? (
            <DayCard
              day={day}
              amount={day === 2 ? 10 : 5}
              status={false}
              currentDay={day}
              className={'max-w-[6rem]'}
            />
          ) : (
            <DayLongCard
              day={5}
              amount={15}
              status={false}
              currentDay={5}
              className={'max-w-[13rem] text-sm'}
            />
          )}
          <div className="w-full bg-green-success p-2 text-center rounded-sm shadow-md capitalize text-jacarta-800 font-semibold">
            {t('Reward claimed successfully')}
          </div>
        </ModalBody>
        <ModalFooter className="justify-center">
          <Button
            onClick={closeModal}
            className={cn(primaryButton, 'px-16')}
            size="sm"
            radius="full"
          >
            {t('Close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SuccessClaimModal;
