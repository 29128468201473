export const amountChange = (value) => {
  if (value == '') return value;

  if (Number(value) < 0 || Number(value) > 2000 || isNaN(Number(value)))
    return null;

  let num = value;
  const splitted = num.split('.');
  if (splitted[1]?.length > 2) {
    num = splitted[0] + '.' + splitted[1].slice(0, 2);
  }

  return num;
};

export const getFirstDayOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  return date.toISOString().split('T')[0];
};

export const getToday = () => new Date().toISOString().split('T')[0];

export const containsUrl = (text: string): boolean => {
  const urlPattern =
    /(?:https?:\/\/|www\.|ws:\/\/|\b[a-z0-9]+\.(?:com|net|org|io|co|uk|edu|gov|tk|buzz|xyz|top|ga|ml|info|cf|gq|icu|gg|wang|live|cn|online|host|us|ru|store|in|fr|pl|id|beauty|eu|rip)\b)/i;

  return urlPattern.test(text);
};

export const formatNumber = (num: number | string) =>
  new Intl.NumberFormat().format(+num);