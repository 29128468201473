import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  completionText,
  disableSeconds,
}) => {
  if (completed) {
    return <Completionist text={completionText} />;
  } else {
    return (
      <span className="shrink-0 whitespace-nowrap *:tabular-nums space-x-1 font-display">
        <span>{days}d</span>
        <span>{hours}h</span>
        <span>{minutes}m</span>
        {!disableSeconds && <span>{seconds}s</span>}
      </span>
    );
  }
};

const Completionist = ({ text }) => {
  return <span className="shrink-0 whitespace-nowrap">{text}</span>;
};

const LiveCountdown = ({
  time,
  completionText,
  disableSeconds = false,
  onComplete,
}: {
  time: Date;
  completionText?: string;
  disableSeconds?: boolean;
  onComplete?: () => void;
}) => {
  const [targetEndTime, setTargetEndTime] = useState(Date.now() + 1000);

  useEffect(() => {
    const now = dayjs();
    const dt = dayjs(time);
    setTargetEndTime(Date.now() + dt.diff(now));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Countdown
      date={targetEndTime}
      renderer={(v) => renderer({ ...v, completionText, disableSeconds })}
      onComplete={onComplete}
    />
  );
};

export default LiveCountdown;
