import WhatsappButton from '@/components/WhatsappButton';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import DefaultEndbar from './defaultEndbar';
import LeaderboardEndbar from './leaderboardEndbar';

function Endbar() {
  const { asPath } = useRouter();

  const getEndbar = useMemo(() => {
    switch (asPath) {
      case '/leaderboard':
        return <LeaderboardEndbar />;

      default:
        return <DefaultEndbar />;
    }
  }, [asPath]);

  const pathsToHideIn = ['/admonitor', '/account-settings'];

  if (pathsToHideIn.some((path) => asPath.includes(path))) {
    return <></>;
  }

  return (
    <aside className="md:sticky top-[64px] sticky-0 h-[calc(100vh-64px)] w-80 hidden md:flex flex-col shadow-lg z-40 py-3 px-2 gap-2 border-s-1 dark:border-jacarta-700 border-jacarta-100 bg-[#f7f5ff] dark:bg-jacarta-800">
      <div className="overflow-auto px-1 flex-1">{getEndbar}</div>

      <div className="p-1">
        <WhatsappButton />
      </div>
    </aside>
  );
}

export default Endbar;
