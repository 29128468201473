import { t } from 'i18next';
import { signOut } from 'next-auth/react';
import React from 'react';
import { MdLogout, MdSettings } from 'react-icons/md';

const useNavbarLinks = () => {
  const communityData = [
    {
      id: 30,
      label: t('MMR'),
      href: '/matchmaking-rating',
    },
    {
      id: 31,
      label: t('Affiliate'),
      href: '/affiliate-program',
    },
    {
      id: 29,
      label: t('How_To_Win'),
      href: '/how-to-win',
    },
    {
      id: 21,
      label: t('Gamerg_Requests'),
      href: '/gamerg-requests',
    },
  ];

  const helpCenterData = [
    {
      id: 7,
      label: t('FAQ_Short'),
      href: '/faq',
    },
    {
      id: 8,
      label: t('Contact_Us'),
      href: '/contact',
    },
  ];

  const sidebarListData = [
    {
      label: t('Account Settings'),
      href: '/dashboard/account-settings',
      icon: <MdSettings fontSize={20} />,
    },
    {
      label: t('FAQ_Short'),
      href: '/faq',
      icon: <FAQIcon />,
    },
    {
      label: t('Help_Center'),
      href: '/contact',
      icon: <HelpCenterIcon />,
    },
    {
      label: t('Log Out'),
      action: signOut,
      icon: <MdLogout fontSize={20} />,
    },
  ];

  return { helpCenterData, communityData, sidebarListData };
};

export default useNavbarLinks;

const FAQIcon = ({ fill, fontSize, width, height }) => (
  <svg
    fontSize={fontSize ?? '18px'}
    width={width ?? '20px'}
    height={height ?? '20px'}
    viewBox="0 0 24 24"
    fill={fill ?? 'currentColor'}
  >
    <path d="M0 0h24v24H0V0z" fill="none"></path>
    <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6z"></path>
    <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5.99 13c-.59 0-1.05-.47-1.05-1.05 0-.59.47-1.04 1.05-1.04.59 0 1.04.45 1.04 1.04-.01.58-.45 1.05-1.04 1.05zm2.5-6.17c-.63.93-1.23 1.21-1.56 1.81-.13.24-.18.4-.18 1.18h-1.52c0-.41-.06-1.08.26-1.65.41-.73 1.18-1.16 1.63-1.8.48-.68.21-1.94-1.14-1.94-.88 0-1.32.67-1.5 1.23l-1.37-.57C11.51 5.96 12.52 5 13.99 5c1.23 0 2.08.56 2.51 1.26.37.61.58 1.73.01 2.57z"></path>
  </svg>
);
const HelpCenterIcon = ({ fill, fontSize, width, height }) => (
  <svg
    fontSize={fontSize ?? '18px'}
    width={width ?? '20px'}
    height={height ?? '20px'}
    viewBox="0 0 24 24"
    fill={fill ?? 'currentColor'}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M12 1 3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
  </svg>
);
