import { createSlice } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AppDispatch, AppStore, RootState } from './store';

const initialState = {
  checkWithdrawRequest: false,
  complycubeStatus: false,

  checkAccountSet: false,
  checkBrawl: false,

  checkCOR: false, // Check country of Residence
  checkDOB: false, // Check DOB
  checkPhone: false, // Check Phone

  applyStreamerModal: false,

  loginModal: false,
  setupAccountModal: false,

  successModal: false,

  dashboardTab: 'tournaments',
  liveKey: 0,
  selectedGame: '',
  accountSettingsTab: 'account',
  tournamentModal: false,
  tournamentModalAction: {
    name: '',
    status: '',
    title: '',
    message: '',
    addPhone: false,
    noIcon: false,
    removeCloseButton: false,
  },
  value: null,

  joinModal: false,

  setCountryOfResidenceModal: false,

  setPhoneNumberModal: false,

  setDOBModal: false,

  trendingCategorySorText: '',

  depositModal: false,
  _1D3Modal: false,
  _1D3Url: false,

  giftcardModalWithdraw: false,

  banCancelModal: false,

  reportUserModal: false,

  connectBrawlModal: false,

  bidsModal: false,

  userFullNameModal: false,

  // withdraw status,
  withdrawActionStatus: false,

  // profile modals status
  bioModalIsOpen: false,
  profileImageModalIsOpen: false,
  profileImageBorderModalIsOpen: false,
  userSocialsModalIsOpen: false,
  userSocialsButtonType: '',
  userSocialLink: '',
  battleCardModalIsOpen: false,
  battleBorderModalIsOpen: false,

  creditModalIsOpen: false,

  creditSuccessModalIsOpen: false,
  creditSuccessModalDay: null, // number or null

  searchModalIsOpen: false,
  refetchPublicProfile: false,
  refetchProfile: false,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    AccountSet: (state) => {
      state.checkAccountSet = !state.checkAccountSet;
    },

    setBioModal: (state) => {
      state.bioModalIsOpen = !state.bioModalIsOpen;
    },

    setCreditModal: (state, { payload }) => {
      state.creditModalIsOpen = payload;
    },
    setCreditSuccessModal: (state, { payload }) => {
      state.creditSuccessModalIsOpen = payload?.isOpen;
      state.creditSuccessModalDay = payload?.day;
    },
    setSearchModal: (state, { payload }) => {
      state.searchModalIsOpen = payload;
    },
    setRefetchPublicProfile: (state) => {
      state.refetchPublicProfile = !state.refetchPublicProfile;
    },
    setRefetchProfile: (state) => {
      state.refetchProfile = !state.refetchProfile;
    },
    setProfileImageModal: (state) => {
      state.profileImageModalIsOpen = !state.profileImageModalIsOpen;
    },
    setProfileImageBorderModal: (state) => {
      state.profileImageBorderModalIsOpen =
        !state.profileImageBorderModalIsOpen;
    },
    setUserSocialsModal: (state, { payload }) => {
      state.userSocialsModalIsOpen = !state.userSocialsModalIsOpen;
      state.userSocialsButtonType = payload?.type;
      state.userSocialLink = payload?.url;
    },
    setBattleCardModal: (state) => {
      state.battleCardModalIsOpen = !state.battleCardModalIsOpen;
    },
    setBattleBorderModal: (state) => {
      state.battleBorderModalIsOpen = !state.battleBorderModalIsOpen;
    },

    WithdrawRequestSet: (state) => {
      state.checkWithdrawRequest = !state.checkWithdrawRequest;
    },

    SetComplycubeStatus: (state) => {
      state.complycubeStatus = !state.complycubeStatus;
    },

    BrawlSet: (state) => {
      state.checkBrawl = !state.checkBrawl;
    },

    CORSet: (state) => {
      state.checkCOR = !state.checkCOR;
    },

    DOBSet: (state) => {
      state.checkDOB = !state.checkDOB;
    },

    PhoneSet: (state) => {
      state.checkPhone = !state.checkPhone;
    },

    banCancelModalShow: (state) => {
      state.banCancelModal = true;
    },
    banCancelModalHide: (state) => {
      state.banCancelModal = false;
    },

    connectBrawlModalShow: (state) => {
      state.connectBrawlModal = true;
    },
    connectBrawlModalHide: (state) => {
      state.connectBrawlModal = false;
    },

    setCountryOfResidenceModalShow: (state) => {
      state.setCountryOfResidenceModal = true;
    },
    setCountryOfResidenceModalHide: (state) => {
      state.setCountryOfResidenceModal = false;
    },

    setPhoneNumberModalShow: (state) => {
      state.setPhoneNumberModal = true;
    },
    setPhoneNumberModalHide: (state) => {
      state.setPhoneNumberModal = false;
    },

    setDOBModalShow: (state) => {
      state.setDOBModal = true;
    },
    setDOBModalHide: (state) => {
      state.setDOBModal = false;
    },

    setupAccountModalShow: (state) => {
      state.setupAccountModal = true;
    },
    setupAccountModalHide: (state) => {
      state.setupAccountModal = false;
    },

    loginModalShow: (state) => {
      state.loginModal = true;
    },
    loginModalHide: (state) => {
      state.loginModal = false;
    },

    successModalShow: (state) => {
      state.successModal = true;
    },
    changeWithdrawActionStatus: (state) => {
      state.withdrawActionStatus = !state.withdrawActionStatus;
    },
    successModalHide: (state) => {
      state.successModal = false;
    },
    setValueAction: (state, action) => {
      state.value = action.payload;
    },

    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload;
    },
    setLiveKey: (state) => {
      state.liveKey = Math.random();
    },
    setSelectedGame: (state, action) => {
      state.selectedGame = action.payload;
    },

    setAccountSettingsTab: (state, action) => {
      state.accountSettingsTab = action.payload;
    },

    tournamentModalShow: (state) => {
      state.tournamentModal = true;
    },

    tournamentModalHide: (state) => {
      state.tournamentModal = false;
    },

    setTournamentModalAction: (state, action) => {
      state.tournamentModalAction = action.payload;
    },

    joinModalShow: (state) => {
      state.joinModal = true;
    },
    joinModalHide: (state) => {
      state.joinModal = false;
    },

    setJoinAction: (state, action) => {
      state.value = action.payload;
    },

    userFullNameModalShow: (state) => {
      state.userFullNameModal = true;
    },
    userFullNameModalHide: (state) => {
      state.userFullNameModal = false;
    },

    depositModalShow: (state) => {
      state.depositModal = true;
    },
    depositModalHide: (state) => {
      state.depositModal = false;
    },

    _1D3ModalShow: (state, action) => {
      state._1D3Modal = true;
      state._1D3Url = action.payload;
    },
    _1D3ModalHide: (state) => {
      state._1D3Modal = false;
      state._1D3Url = false;
    },

    giftcardWithdrawModalShow: (state) => {
      state.giftcardModalWithdraw = true;
    },
    giftcardWithdrawModalHide: (state) => {
      state.giftcardModalWithdraw = false;
    },

    reportUserModalShow: (state) => {
      state.reportUserModal = true;
    },
    reportUserModalHide: (state) => {
      state.reportUserModal = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  AccountSet,
  CORSet,
  DOBSet,
  PhoneSet,
  WithdrawRequestSet,
  SetComplycubeStatus,

  userFullNameModalShow,
  userFullNameModalHide,

  setupAccountModalShow,
  setupAccountModalHide,

  loginModalShow,
  loginModalHide,

  /// Succlert Modal
  successModalShow,
  successModalHide,
  setValueAction,
  changeWithdrawActionStatus,

  setDashboardTab,
  setLiveKey,
  setSelectedGame,
  setAccountSettingsTab,
  tournamentModalShow,
  tournamentModalHide,
  setTournamentModalAction,

  // Join Tourney
  setJoinAction,
  joinModalShow,
  joinModalHide,

  // Country of Residence

  setCountryOfResidenceModalShow,
  setCountryOfResidenceModalHide,

  // Phone Number

  setPhoneNumberModalShow,
  setPhoneNumberModalHide,

  // Dob2
  setDOBModalShow,
  setDOBModalHide,
  BrawlSet,

  // Ban Cancel
  banCancelModalHide,
  banCancelModalShow,

  depositModalHide,
  depositModalShow,

  _1D3ModalHide,
  _1D3ModalShow,

  reportUserModalShow,
  reportUserModalHide,

  giftcardWithdrawModalShow,
  giftcardWithdrawModalHide,

  connectBrawlModalHide,
  connectBrawlModalShow,

  // user public profile modals
  setUserSocialsModal,
  setProfileImageModal,
  setProfileImageBorderModal,
  setBattleCardModal,
  setBattleBorderModal,
  setBioModal,
  setCreditModal,
  setSearchModal,
  setCreditSuccessModal,
  setRefetchPublicProfile,
  setRefetchProfile,
} = counterSlice.actions;

export default counterSlice.reducer;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;
