import ScoreCard from '@/components/leaderboard/scoreCard';
import SeasonPrize from '@/components/leaderboard/seasonPrize';
import useLeaderboard from '@/hooks/useLeaderboard';
import useLeaderboardSeason from '@/hooks/useLeaderboardSeason';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

const LeaderboardEndbar = () => {
  const { t } = useTranslation();
  const { season } = useLeaderboardSeason();
  const { data } = useLeaderboard('0');

  return (
    <div>
      <h2 className="text-lg px-5 py-1 my-2 border border-accent rounded-full text-center">
        <span className="font-display">
          {t('Leaderboard')} {t('Season')} {season == 0 ? 1 : season}
        </span>
      </h2>

      <SeasonPrize />

      {data?.userScore && (
        <>
          <Divider className="mt-3" />

          <h2 className="text-lg  px-5 py-1 my-2 border border-accent rounded-full text-center">
            <span className="font-display">{t('Your Score & Rank')}</span>
          </h2>

          <ScoreCard userScore={data?.userScore} />
        </>
      )}
    </div>
  );
};

export default LeaderboardEndbar;
