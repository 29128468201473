import dayjs from 'dayjs';

const useLeaderboardSeason = () => {
  const initialSeason = dayjs('2024-12-01T00:00:00Z');
  const seasonStart = dayjs().utc().startOf('M');
  const seasonEnd = dayjs().utc().endOf('M').subtract(3, 'day');

  const season = seasonStart.diff(initialSeason, 'M');

  return { season: season > 0 ? season : 0, seasonStart, seasonEnd };
};

export default useLeaderboardSeason;
