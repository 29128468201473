import {
  Button,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/react';
import { signIn } from 'next-auth/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isRunningInWebView from '../../lib/utils';
import { loginModalHide } from '../../redux/counterSlice';
import { modalStyle } from '../../theme/nextUI';

const LoginModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loginModal } = useSelector((state) => state.counter);

  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    setIsWebView(isRunningInWebView());
  }, [loginModal]);

  async function handleGoogleSignin() {
    await signIn('google', { callbackUrl: window.location.href });
  }

  async function handleFacebookSignin() {
    signIn('facebook', { callbackUrl: '/' });
  }

  const hideModal = () => {
    dispatch(loginModalHide());
  };

  return (
    <Modal
      isOpen={loginModal}
      size="sm"
      onClose={hideModal}
      classNames={modalStyle}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Login_Title')}</h5>
        </ModalHeader>
        <ModalBody className="flex flex-col items-center gap-2">
          {isWebView && (
            <div
              className="bg-red border border-red text-white px-4 py-3 rounded relative mb-3 text-justify"
              role="alert"
            >
              {t('Login_Error')}

              <span className="absolute top-0 bottom-0 right-0 px-4 py-3"></span>
            </div>
          )}

          <Button
            size="lg"
            radius="full"
            variant="faded"
            className="font-semibold w-full"
            onClick={handleGoogleSignin}
            disabled={isWebView}
          >
            <Image
              src="/images/social/google.svg"
              className="h-6 w-6"
              alt="Google"
            />
            <span>{t('Sign in with Google')}</span>
          </Button>
          <Button
            size="lg"
            radius="full"
            variant="faded"
            className="font-semibold w-full"
            onClick={handleFacebookSignin}
            disabled={isWebView}
          >
            <Image
              src="/images/social/facebook.svg"
              className="h-6 w-6"
              alt="Google"
            />
            <span>{t('Sign in with Facebook')}</span>
          </Button>

          <div className="mt-3">
            <p>{t('By logging in, you agree to GamerG.gg')}</p>
            <Link
              className="cursor-pointer"
              onClick={() => dispatch(loginModalHide())}
            >
              {t('Privacy_Policy')}
            </Link>

            <span className="px-1">{t('and')}</span>
            <Link
              className="cursor-pointer"
              onClick={() => dispatch(loginModalHide())}
            >
              {t('Terms_and_Conditions')}
            </Link>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
