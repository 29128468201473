import nodeAPI from '@/lib/nodeapi';
import { Announcement } from '@/types/types';
import { Skeleton } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

const Announcements = () => {
  const { data: session } = useSession();
  const router = useRouter();

  const { data, isLoading } = useQuery<Announcement>({
    queryKey: ['Announcement', session?.secure],
    queryFn: async () => {
      const { status, message } = await nodeAPI(
        'GET',
        session?.secure,
        'extra/getAnnouncement',
      );

      if (!status) throw new Error(message);

      return message;
    },
  });

  return (
    <>
      <h2 className="text-lg  px-5 py-1 my-2 border border-accent rounded-full text-center font-display">
        {t(`Announcements`)}
      </h2>

      <div className="space-y-2 border border-jacarta-200 dark:border-jacarta-600 rounded-md p-2 text-center">
        {isLoading ? (
          <Skeleton className="w-full h-5 my-0.5 rounded-full" />
        ) : (
          <p>
            {router.locale === 'en-US'
              ? data?.announcement_en
              : data?.announcement_ar}
          </p>
        )}
      </div>
    </>
  );
};

export default Announcements;
