import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import useSound from 'use-sound';

const useNotifications = () => {
  let socket;
  const [initialValue, setInitialValue] = useState(null);
  const [notificationsList, setNotificationsList] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);

  const { data: session, status } = useSession();

  const [play] = useSound(
    process.env.NEXT_PUBLIC_CONNECTOR_API + 'sound/notification-sound.mp3',
  );

  useEffect(() => {
    if (status === 'authenticated') {
      // Only try to connect if the session status is authenticated
      socketInitializer();
    }

    // Cleanup function to disconnect socket when component unmounts
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [status]); // Dependency on the session status

  const socketInitializer = () => {
    socket = io(process.env.NEXT_PUBLIC_NODE_STORAGE, {
      auth: {
        token: session?.secure,
      },
    });

    socket.on('connect', () => {
      socket.emit('init_notifications');
    });

    socket.on('notifications', (data) => {
      if (data.status) {
        setUnseenCount(data.totalUnseen);

        // Handle the notification data. For instance, you can set it in the state
        setNotificationsList(data.results);
      } else {
        console.error('ERR: ' + data.message);
      }
    });

    socket.on('auth_error', (error) => {
      console.error('Authentication error:', error.message);
      // Handle the authentication error as needed
    });
  };

  useEffect(() => {
    if (initialValue === null && typeof unseenCount !== 'undefined') {
      setInitialValue(unseenCount);
    } else if (initialValue !== null && unseenCount > initialValue) {
      play();
      setInitialValue(unseenCount);
    }
  }, [unseenCount]); // <-- Adjusted the dependency array

  return { notificationsList, unseenCount };
};

export default useNotifications;
