import LiveTournamentSelector from '@/components/dashboard/live/liveTournamentSelector';
import TournamentCard from '@/components/play/tournamentCard';
import useLiveTournament from '@/hooks/useLiveTournament';
import { useAppSelector } from '@/redux/counterSlice';
import { Spinner } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import Announcements from '../../play/announcements';
import TopWeeklyEarners from '../../play/topWeeklyEarners';
import useLiveTournaments from '@/hooks/useLiveTournaments';

const DefaultEndbar = () => {
  const { asPath } = useRouter();
  const { dashboardTab: tab } = useAppSelector((state) => state.counter);

  const isTournamentsOrLive =
    asPath === '/dashboard' || asPath === '/dashboard?t=live';

  return (
    <>
      {isTournamentsOrLive && <LiveTournamentSelector />}
      {tab == 'live' && <TournamentPreivew />}
      {tab != 'live' && (
        <>
          <Announcements />
          <TopWeeklyEarners />
        </>
      )}
    </>
  );
};

export default DefaultEndbar;

const TournamentPreivew = () => {
  const { t } = useTranslation();
  const tournaments = useLiveTournaments();
  const { game: tournament } = useLiveTournament();

  return (
    <>
      <h2 className="text-lg px-5 py-1 my-2 border border-accent rounded-full text-center font-display">
        {t('Tournament Details')}
      </h2>

      <div className="flex flex-col items-center">
        {tournaments.isFetching ? (
          <Spinner size="sm" className="my-10 mx-auto" />
        ) : (
          tournament && (
            <TournamentCard
              tournament={tournament}
              className="w-full"
              removeHeader
              removeAD
              removePrize
              removePills
              removeActions
            />
          )
        )}
      </div>
    </>
  );
};
