import { Button } from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import useTracker, { TrakerTypes } from '../../hooks/useTraker';
import { useRouter } from 'next/router';

export default function LoginButton({ classNames, size }) {
  const { status } = useSession();
  const { push } = useRouter();
  // trakers
  // const doLogin = useTracker({ key: TrakerTypes.OpenModalLogin });
  const doLogin = () => push('/login');

  return (
    status == 'unauthenticated' && (
      <Button
        size={size ?? 'md'}
        radius="full"
        className={`cursor-pointer bg-accent hover:bg-accent-dark w-52 2xl:w-60 font-semibold text-white transition-all ${classNames}`}
        onClick={doLogin}
      >
        {t('Login / Register')}
      </Button>
    )
  );
}
