import {
  Button,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '../../hooks/useBreakpoint';
import useCheckDisclamer from '../../hooks/useCheckDisclamer';
import useProfileName from '../../hooks/useProfileName';
import { useControlPanelStore } from '../../store/useControlPanelStore';
import CtrMenu from './CtrMenu';
import LoginButton from './LoginButton';
// import MenuButtonTrigger from './MenuButtonTrigger';
import { RiCloseFill, RiMenu5Fill } from 'react-icons/ri';

export default function LoginButtonWithMenu() {
  const { isOpen, setIsOpen } = useControlPanelStore();
  const { status } = useSession();
  const { isLess: isLessLargeScreen } = useBreakpoint('lg');
  const { isLess: isLessMd } = useBreakpoint('sm');
  const { t } = useTranslation();
  const { isData } = useCheckDisclamer();
  const { profileName } = useProfileName();

  const isAuthenticated = status === 'authenticated' && !isData?.banned;

  return (
    <>
      <LoginButton
        classNames={isLessMd ? 'w-fit text-xs' : ''}
        size={isLessMd ? 'sm' : 'md'}
      />
      {isAuthenticated && isLessLargeScreen && (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen} shouldBlockScroll>
          {/* <Button
                 className="w-42 2xl:w-60 bg-accent focus-visible:bg-accent-dark rounded-full xl:pe-1 text-center font-semibold text-white transition-all hidden lg:flex justify-evenly"
                 endContent={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                 >
                 <span className="flex items-center text-md">
                   {t('Hi,')} {profileName} 👋
                   </span>
               </Button> */}
          <PopoverTrigger>
            <Button
              radius="full"
              isIconOnly
              variant="faded"
              className={cn(
                'font-semibold hover:opacity-100 hover:bg-accent dark:hover:bg-accent  hover:text-white dark:hover:text-white shadow-sm lg:hidden',
                isOpen
                  ? 'bg-accent dark:bg-accent text-white'
                  : 'bg-white dark:bg-transparent border-accent text-accent dark:text-accent  ',
                'menu-step-1', // this is for steps tour
              )}
              aria-label="nav-menu-icon"
            >
              {isOpen ? (
                <RiCloseFill fontSize={'20px'} />
              ) : (
                <RiMenu5Fill
                  // className="text-accent dark:text-accent"
                  fontSize={'22px'}
                />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            aria-label="Static Actions"
            className={'menu-gradient min-w-[350px] lg:min-w-[300px] '}
          >
            <CtrMenu
              profileName={profileName}
              isAuthenticated={isAuthenticated}
            />
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}
