import { Button, cn } from '@nextui-org/react';
import React from 'react';
import { RiCloseFill, RiMenu5Fill } from 'react-icons/ri';

interface Props {
  isOpen: boolean;
}

function MenuButtonTrigger({ isOpen }: Props) {
  return (
    <Button
      radius="full"
      isIconOnly
      variant="faded"
      className={cn(
        'font-semibold hover:opacity-100 hover:bg-accent dark:hover:bg-accent  hover:text-white dark:hover:text-white shadow-sm lg:hidden',
        isOpen
          ? 'bg-accent dark:bg-accent text-white'
          : 'bg-white dark:bg-transparent border-accent text-accent dark:text-accent  ',
      )}
      aria-label="nav-menu-icon"
    >
      {isOpen ? (
        <RiCloseFill fontSize={'20px'} />
      ) : (
        <RiMenu5Fill
          // className="text-accent dark:text-accent"
          fontSize={'22px'}
        />
      )}
    </Button>
  );
}

export default MenuButtonTrigger;
