import {
  Button,
  cn,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import nodeAPI from '../../../lib/nodeapi';
import { depositModalHide } from '../../../redux/counterSlice';
import { useBalanceStore } from '../../../store/useBalance';
import { modalStyle } from '../../../theme/nextUI';
import CurrencyField from '../../CurrencyField';
import Gem from '../../gem';
import PaymentButtons from '../../wallet/PaymentButtons';

const DepositModal = () => {
  const [fromDetailsBtn, setFromDetailsBtn] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: session } = useSession();
  const { setBalance } = useBalanceStore();
  const { depositModal } = useSelector((state) => state.counter);
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState('');
  const [hasCustomAmount, setHasCustomAmount] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [method, setMethod] = useState();
  const [paymentInfo, setPaymentInfo] = useState({
    amount: 0,
    currency: 'USD',
  });
  const cards = [
    { name: t('Treasure'), xAmount: 50, image: '/images/deposit/3.jpg' },
    { name: t('Bag Holder'), xAmount: 20, image: '/images/deposit/2.jpg' },
    { name: t('Stone Collector'), xAmount: 10, image: '/images/deposit/1.jpg' },
  ];

  const handleAmountChange = (e) => {
    const newValue = e.target.value;
    if (
      newValue === '' ||
      (Number(newValue) > 0 &&
        Number(newValue) <= 2000 &&
        !isNaN(Number(newValue)))
    ) {
      setAmount(newValue);
    }
  };

  const hideModal = () => {
    setStep(1);
    dispatch(depositModalHide());
    setAmount('');
    setMethod(null);
  };

  const handlePaymentStatus = async ({
    status,
    response,
    amount,
    currency = 'USD',
  }) => {
    setPaymentStatus(status);
    setStep(3);
    setPaymentInfo({ amount, currency });

    if (status == 'success') {
      const response = await nodeAPI(
        'POST',
        session.secure,
        'extra/checkUserBalance',
        {
          amountToCheck: 0,
        },
      );

      setBalance({
        depositBalance: response.depositBalance,
        prizeUserBalance: response.prizeUserBalance,
      });
    }
  };

  const payNow = () => {
    setTimeout(() => {
      setStep(2);
      setHasCustomAmount(false);
    }, 450);
  };

  const { data: pendingPayments, isPending: pendingPaymentsIsPending } =
    useQuery({
      queryKey: [
        'Pending Payments',
        {
          secure: session?.secure,
          step,
        },
      ],
      queryFn: async () =>
        await nodeAPI(
          'POST',
          session?.secure,
          'finances/checkIfPendingPayments',
        ),
      initialData: { status: false },
      enabled: [3, 4].includes(step),
    });

  return (
    <Modal
      isOpen={depositModal}
      onClose={hideModal}
      size={method === 'CliQ' && step == 3 ? 'lg' : 'xl'}
      scrollBehavior="inside"
      classNames={modalStyle}
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <div className="translate-y-[5px]">
              <Gem size={48} />
            </div>

            <div className="flex flex-col -ms-1">
              <h5 className="modal-title">{t('Gem Packs')}</h5>
              <p className="text-jacarta-400 text-sm -mt-1.5">
                {t('Add gems to your account')}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between gap-3 border-t border-jacarta-100 dark:border-jacarta-600 -m-5 mt-5 px-5 pb-1">
            {step !== 3 && (
              <div className="flex items-center py-3 gap-2 text-sm">
                <button
                  className={cn(step == 1 && 'font-semibold text-accent')}
                  onClick={() => setStep(1)}
                >
                  {t('Select Amount')}
                </button>

                <FaChevronRight
                  className={cn(
                    'text-jacarta-400',
                    router.locale != 'en-US' && 'rotate-180',
                  )}
                  size={12}
                />

                <button
                  className={cn(
                    step == 2 && !method && 'font-semibold text-accent',
                  )}
                  onClick={() => {
                    setStep(2);
                    setMethod(null);
                  }}
                  disabled={amount < 5}
                >
                  {t('Payment Method')}
                </button>

                {method != null && step != 1 && (
                  <>
                    <FaChevronRight
                      className={cn(
                        'text-jacarta-400',
                        router.locale != 'en-US' && 'rotate-180',
                      )}
                      size={12}
                    />
                    <button
                      className={cn(step == 2 && 'font-semibold text-accent')}
                      onClick={() => setStep(2)}
                      disabled={amount < 5}
                    >
                      {t(method)}
                    </button>
                  </>
                )}
              </div>
            )}

            {step == 1 && (
              <div className="flex h-8 gap-px text-white text-lg rounded-full bg-[#090c1d] ps-3 pe-1 pt-px select-none leading-8 tracking-wide [&>span]:font-display">
                <span className="ltr:text-[20px] text-[22px]">$</span>
                <span>1</span>
                <span>=</span>
                <span className="text-purple">1</span>
                <span className="shrink-0 translate-y-px -translate-x-[3px]">
                  <Gem size={24} />
                </span>
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          {step == 1 && (
            <>
              <div className="grid sm:grid-cols-3 gap-3">
                {cards.map(({ xAmount, image, name }) => (
                  <div key={xAmount}>
                    <Button
                      className={cn(
                        'group w-full h-auto sm:flex-col shadow-sm justify-between rounded-large bg-[#090c1d] flex-1 text-accent p-3 ring-0 ring-accent transition-all select-none',
                        amount == xAmount && '!ring-2',
                      )}
                      onPress={() => {
                        setAmount(xAmount);
                        setTimeout(() => {
                          setStep(2);
                          setHasCustomAmount(false);
                        }, 450);
                      }}
                    >
                      <div>
                        <h6 className="font-bold text-lg font-display">
                          {name}
                        </h6>

                        <span className="-mt-2">
                          {xAmount} {t('Gems')}
                        </span>
                      </div>

                      <div className="order-first sm:order-none">
                        <Image
                          src={image}
                          alt={name}
                          className="w-[80px] sm:w-[120px]"
                          disableSkeleton
                        />
                      </div>

                      <hr className="text-jacarta-500 hidden sm:block" />

                      <div className="flex flex-col w-28 sm:w-full">
                        <span
                          className={cn(
                            'p-1 w-full text-lg border border-accent mt-2 text-white inline-block rounded-full font-bold bg-accent group-hover:bg-accent-dark',
                          )}
                        >
                          ${xAmount}
                        </span>
                        {!cookies.get('currency') ||
                        cookies.get('currency') === 'USD' ? null : (
                          <p className="p-2 shadow-lg rounded-full mt-2 text-xs bg-secondary-50 text-md font-bold tracking-tight sm:hidden w-2/3 mx-auto">
                            ~<CurrencyField amount={xAmount} />
                          </p>
                        )}
                      </div>
                    </Button>

                    {!cookies.get('currency') ||
                    cookies.get('currency') === 'USD' ? null : (
                      <p className="p-2 shadow-lg rounded-full mt-2 bg-secondary-50 text-md font-bold text-center tracking-tight hidden sm:block">
                        ~<CurrencyField amount={xAmount} />
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div className="relative w-full h-0.5 bg-jacarta-100 dark:bg-jacarta-600 mt-6 mb-5">
                <div className="font-semibold bg-white dark:bg-jacarta-700 absolute px-3 left-1/2 -translate-x-1/2 -translate-y-3 select-none uppercase">
                  {t('Or')}
                </div>
              </div>

              {!hasCustomAmount ? (
                <Button
                  className="w-full shadow-sm rounded-large bg-[#090c1d] h-auto text-white flex justify-around shrink-0 relative"
                  onPress={() => {
                    setTimeout(() => {
                      setHasCustomAmount(true);
                    }, 450);
                  }}
                >
                  <Image
                    src={'/images/deposit/4.jpg'}
                    width={90}
                    height={90}
                    alt="box"
                    disableSkeleton
                  />
                  <span className="text-lg uppercase sm:text-xl font-bold tracking-tight text-accent absolute inset-0 z-20 bg-black/60 backdrop-blur-[1px] content-center sm:static sm:bg-transparent">
                    {t('Custom Gem Amount')}
                  </span>
                  <Image
                    src={'/images/deposit/4.jpg'}
                    width={90}
                    height={90}
                    alt="box"
                    disableSkeleton
                  />
                </Button>
              ) : (
                <div className="grid grid-cols-2 gap-5 items-end shadow-sm rounded-large bg-[#090c1d] h-auto text-white px-4 py-3">
                  <div className="flex flex-col items-start gap-1">
                    <label htmlFor="amount">{t('Enter Amount')}</label>
                    <div className="relative">
                      <input
                        type="text"
                        id="amount"
                        value={amount}
                        onChange={handleAmountChange}
                        pattern="[0-9]*"
                        className="border-2 pe-[2.5rem] bg-[#090c1d] dark:bg-jacarta-700 hover:ring-accent/10 focus:ring-accent border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 px-3"
                        placeholder={t('Enter amount...')}
                        onKeyDown={({ key }) => {
                          if (key == 'Enter' && amount >= 5) payNow();
                        }}
                      />

                      <div className="absolute end-2 top-2.5">
                        <Gem size={30} />
                      </div>
                    </div>
                  </div>
                  <Button
                    className={cn(
                      'py-3 px-1 w-full h-auto text-lg bg-accent hover:bg-accent-dark text-white border border-accent inline-block rounded-full font-bold transition-colors  group-hover:bg-accent-dark',
                    )}
                    onPress={payNow}
                    isDisabled={amount < 5}
                  >
                    {t('Pay Now')}
                  </Button>
                </div>
              )}
            </>
          )}

          {step === 2 && (
            <PaymentButtons
              onPayment={handlePaymentStatus}
              amount={amount}
              setAmount={setAmount}
              method={method}
              setMethod={setMethod}
              onClose={hideModal}
              setStep={setStep}
              setFromDetailsBtn={setFromDetailsBtn}
            />
          )}

          {/* if step 3 && method = cliq then return the new details modal */}
          {step === 3 &&
            (method === 'CliQ' ? (
              <>
                <div className="flex flex-col gap-5 items-center">
                  <h2 className="text-center text-lg">
                    {t(
                      'Once the funds are received in the company account, we will process your deposit within 24 hours',
                    )}
                  </h2>

                  <div className="flex flex-col items-center p-6 rounded-lg border border-accent">
                    {pendingPaymentsIsPending ? (
                      <Spinner color="secondary" />
                    ) : (
                      <>
                        <span className="text-xl">{t('CliQ Account')}</span>
                        <span className="text-7xl font-medium text-accent leading-[72px] mb-2">
                          {t('GAMERG')}
                        </span>
                        <span className="text-[18px] font-medium">
                          {t('Transfer Amount')} :{' '}
                          {(
                            Number(
                              pendingPayments?.message?.[0]?.deposit_amount,
                            ) +
                            Number(pendingPayments?.message?.[0]?.fee_amount)
                          ).toFixed(1)}{' '}
                          {t('JOD')}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="flex flex-col self-start ms-2 ">
                    <span>
                      {t('Company Name')} : {t('Al Faragh Al Rakami')}
                    </span>
                    <span>
                      {t('Bank Name')} : {t('Bank al Etihad')}
                    </span>
                  </div>
                  {/* {!fromDetailsBtn && (
                    <Button
                      variant="flat"
                      className="bg-accent w-full text-white font-semibold"
                      onPress={() => setStep(4)}
                      radius="full"
                    >
                      {t('Next')}
                    </Button>
                  )} */}
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center gap-2 text-center">
                <motion.img
                  initial={{ opacity: 0, scale: 0.7, rotate: 10 }}
                  animate={{ opacity: 1, scale: 1, rotate: 0 }}
                  exit={{ opacity: 0, scale: 0.7, rotate: 10 }}
                  transition={{ duration: 0.5 }}
                  src={`/images/payments/status/${paymentStatus}.png`}
                  width={150}
                  height={150}
                  alt="Payment Successfull"
                />
                {paymentStatus == 'success' && (
                  <h6 className="text-lg font-semibold text-green-500">
                    {t('Payment Successfull')}
                  </h6>
                )}
                {paymentStatus == 'fail' && (
                  <>
                    <h6 className="text-lg font-semibold text-red-500">
                      {t('Payment Failed')}
                    </h6>

                    <div className="flex justify-end my-2">
                      <Button
                        variant="flat"
                        className="bg-accent text-white font-semibold"
                        radius="full"
                        onPress={() => {
                          setStep(2);
                          setMethod(null);
                        }}
                      >
                        {t('Try another method')}
                      </Button>
                    </div>
                  </>
                )}

                {paymentStatus == 'pending' && (
                  <div>
                    <h6 className="text-lg font-semibold text-purple">
                      {t('Payment Pending')}
                    </h6>
                    <p className="font-semibold text-jacarta-600 dark:text-jacarta-100">
                      {t('Your Deposit is being processed')}
                    </p>
                  </div>
                )}

                {paymentStatus != 'fail' && (
                  <>
                    <div className="grid grid-cols-2 gap-5">
                      <div>
                        <span>{t('Amount')}:</span>
                        <span className="text-accent ms-1">
                          {pendingPayments?.message?.[0]?.deposit_amount ??
                            `${paymentInfo.amount} ${paymentInfo.currency}`}
                        </span>
                      </div>
                      <div>
                        <span>{t('Method')}:</span>
                        <span className="text-accent ms-2 capitalize">
                          {t(method)}
                        </span>
                      </div>
                    </div>

                    <div className="flex justify-end my-2 gap-2">
                      <Button
                        variant="flat"
                        // size="lg"
                        radius="full"
                        className="bg-accent text-white font-semibold"
                        onPress={hideModal}
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DepositModal;
