import { Tournament } from '@/types/types';
import { create } from 'zustand';

interface State {
  tournament?: Tournament;
  seats: number;
  setTournament: (scrollPosition: State['tournament']) => void;
  setSeats: (seats: State['seats']) => void;
}

export const useTournamentStore = create<State>()((set) => ({
  tournament: undefined,
  seats: 1,
  setTournament: (tournament) => set(() => ({ tournament, seats: 1 })),
  setSeats: (seats) => set(() => ({ seats })),
}));
