import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { successModalHide } from '../../redux/counterSlice';
import { modalStyle } from '../../theme/nextUI';

const SuccessModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();
  const { successModal, value } = useSelector((state) => state.counter);

  let src = '/images/general/check.png';
  if (value?.error) {
    src = '/images/general/error.png';
  } else if (value?.alert) {
    src = '/images/general/alert.png';
  } else if (value?.success) {
    src = '/images/general/check.png';
  }

  return (
    <Modal
      isOpen={successModal}
      onClose={() => dispatch(successModalHide())}
      classNames={modalStyle}
    >
      <ModalContent>
        <ModalHeader>
          <h5 className="modal-title"> {value?.title}</h5>
        </ModalHeader>
        <ModalBody>
          <div className="flex gap-5 items-center">
            <Image
              className="rounded-none"
              src={src}
              width={36}
              height={36}
              alt="icon"
              disableSkeleton
              disableAnimation
            />

            <p className="col-span-4 bg-gray-400 text-start">
              {value?.message}
            </p>
          </div>

          {value?.isTip && value?.isTip === true && (
            <div className="px-4 mt-2 text-start">
              <ul className="list-disc ps-4">
                {router.locale === 'ar-SA' ? (
                  <>
                    <li>
                      عندما تبدأ البطولة، ابدأ اللعب في وضع
                      {value?.gameMode}.
                    </li>
                    <li>العب أكبر عدد ممكن من الألعاب.</li>
                    <li>النتيجة النهائية تعتمد على {value?.bestGames}.</li>
                  </>
                ) : (
                  <>
                    <li>
                      When tournament begins, start playing in
                      {value?.gameMode} Mode.
                    </li>
                    <li>Play as many games as you can.</li>
                    <li>
                      Your final score is based on the {value?.bestGames}.
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {value?.isButton && (
            <>
              {!value?.isAction && (
                <Link href={value?.ButtonURL}>
                  <Button
                    radius="full"
                    className={'bg-accent font-semibold text-white'}
                  >
                    {value?.ButtonText}
                  </Button>
                </Link>
              )}
              {value?.isAction && (
                <Button
                  radius="full"
                  className={'bg-accent font-semibold text-white'}
                  onClick={() => {
                    value?.actionFn();
                    dispatch(successModalHide());
                  }}
                >
                  {value?.ButtonText}
                </Button>
              )}
            </>
          )}
          {!value?.removeCloseButton && (
            <Button
              radius="full"
              className={'bg-accent font-semibold text-white'}
              onClick={() => dispatch(successModalHide())}
            >
              {t('Close')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
