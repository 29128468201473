import { Button, Tooltip } from '@nextui-org/react';

interface Props {
  text: string;
}
export const CardTooltip = ({ text }: Props) => {
  return (
    <Tooltip
      // showArrow
      placement="top"
      content={text}
      // className="top-3 end-5"
      classNames={{
        base: [
          // arrow color
          'before:menu-gradient ',
        ],
        content: ['py-2 px-4 shadow-xl', 'menu-gradient'],
      }}
    >
      <Button
        isIconOnly
        variant="flat"
        className="w-fit max-w-fit p-0 h-fit min-w-fit"
        size="sm"
        radius="full"
      >
        <svg
          style={{
            color: '#c1c0c8',
            fill: '#c1c0c8',
            fontSize: '15px',
            width: '15px',
            height: '15px',
          }}
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
        </svg>
      </Button>
    </Tooltip>
  );
};
