import { useQuery } from '@tanstack/react-query';
import cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';

const GEOLOCATION_COOKIE = 'geolocation';

export const useGeolocation = () => {
  const { data: session, status } = useSession();
  const geoCookie = cookies.get(GEOLOCATION_COOKIE);

  const { data: geoUser, isLoading } = useQuery({
    queryKey: ['Geolocation User'],
    queryFn: async () => {
      const res = await nodeAPI('POST', session.secure, 'user/getUserCountry');
      return res.country_code;
    },
    enabled: status == 'authenticated' && !geoCookie,
  });

  const { data: geoApi } = useQuery({
    queryKey: ['Geolocation API'],
    queryFn: async () => {
      const res = await fetch('https://ipapi.co/country_code', {
        method: 'GET',
      });
      return await res.text();
    },
    enabled: status == 'authenticated' && !geoCookie && !geoUser && !isLoading,
  });

  const expires = 1 / 24; // 1 hour
  if (geoUser) cookies.set(GEOLOCATION_COOKIE, geoUser, { expires });
  if (geoApi) cookies.set(GEOLOCATION_COOKIE, geoApi, { expires });

  return geoCookie ?? geoUser ?? geoApi;
};
