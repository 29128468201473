import { create } from 'zustand';

interface CurrencyState {
  latestRate: number;
  digit: number;
  setLatestRate: (rate: number, digit: number) => void;
}

export const useCurrencyStore = create<CurrencyState>()((set) => ({
  latestRate: 1,
  digit: 1,
  setLatestRate: (rate, digit) => set(() => ({ latestRate: rate, digit })),
}));
