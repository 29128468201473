import useAchievementsRanking from '@/hooks/useAchievementsRanking';
import useProfileName from '@/hooks/useProfileName';
import useUserProfile from '@/hooks/useUserProfile';
import { useControlPanelStore } from '@/store/useControlPanelStore';
import { Avatar, Card, CardBody, Image, Skeleton } from '@nextui-org/react';
import { t } from 'i18next';
import { useRouter } from 'next/router';

interface UserCardProps {
  type?: string;
}

export const UserCard = ({ type }: UserCardProps) => {
  const { data: userProfileData } = useUserProfile();
  const { isOpen, setIsOpen } = useControlPanelStore();
  const { locale, push } = useRouter();
  const { profileName } = useProfileName();
  const { data: userRank, isPending: userRankIsPending } =
    useAchievementsRanking();

  const inCtrMenu = type === 'menu';
  // const dispatch = useAppDispatch();

  return (
    <Card className="menu-gradient-50 border shadow-md dark:border-jacarta-600 border-jacarta-100 w-full">
      <CardBody
        className="flex gap-2 flex-row items-center cursor-pointer"
        onClick={() => {
          push(`/u/${userProfileData?.username}`);
          if (isOpen) setIsOpen(false);
        }}
      >
        <Avatar
          alt="user profile picture"
          src={
            userProfileData?.profile_pic ??
            '/images/profile/default-profile-image.jpg'
          }
          className="w-12 h-12 shadow-base"
        />
        <div className="flex flex-col md:gap-1 flex-1">
          {profileName ? (
            <div className=" flex items-center justify-between w-full">
              <p className="text-sm font-medium ">
                {t('Hi,')} {profileName} 👋
              </p>
              {/* {userProfileData?.username ? (
                <Button
                  size="sm"
                  radius="full"
                  className="bg-accent text-white"
                  onClick={() => push(`/u/${userProfileData?.username}`)}
                >
                  {t('Edit Profile')}
                </Button>
              ) : (
                <Button
                  size="sm"
                  radius="full"
                  className="bg-accent text-white"
                  onClick={() => dispatch(setupAccountModalShow())}
                >
                  {t('Verify Account')}
                </Button>
              )} */}
            </div>
          ) : (
            <Skeleton className="w-[100px] h-[10px]" />
          )}
          {userRankIsPending ? (
            <Skeleton className="w-[50px]" />
          ) : userRank?.status ? (
            <div className="flex items-center gap-1 w-full">
              <Image
                disableSkeleton
                src={userRank.ImageURL}
                className="w-4"
                alt="user rank image"
              />
              <p className="text-xs font-medium">
                {locale === 'ar-SA'
                  ? userRank.achievementNameAR
                  : userRank.achievementName}
              </p>
            </div>
          ) : null}
          <span className="text-xs font-medium text-end">
            (lvl {userRank?.rank})
          </span>
        </div>
      </CardBody>
    </Card>
  );
};
