import { useRouter } from 'next/router';
import * as yup from 'yup';
import { ar, en } from 'yup-locales';

export const useYup = () => {
  const router = useRouter();
  const isEn = router.locale == 'en-US';

  ar.mixed.required = ar.mixed.required.replace('هو حقل', '');

  ar.mixed.notType = ar.mixed.required;
  en.mixed.notType = en.mixed.required;

  yup.setLocale(isEn ? en : ar);

  return { Yup: yup, ar, en, currLangList: isEn ? en : ar };
};
