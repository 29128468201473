import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en-US', 'ar-SA'],
    backend: {
      loadPath: `${process.env.NEXT_PUBLIC_CONNECTOR_API}locales/{{lng}}/translation.json`,
    },
    fallbackLng: 'en-US',
    detection: {
      order: [
        'cookie',
        'path',
        'querystring',
        'localStorage',
        'htmlTag',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },

    react: { useSuspense: true },
  });

export default i18n;
