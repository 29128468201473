import CurrencyField from '@/components/CurrencyField';
import { CardTooltip } from './CardTooltip';
import { t } from 'i18next';

interface Props {
  balance: number;
  notUSD?: boolean;
}

export const PrizeCard = ({ balance, notUSD }: Props) => {
  return (
    <div className="flex  gap-2 flex-col flex-1">
      <div className="flex items-center justify-between relative">
        <p className={`text-sm font-semibold text-accent`}>{t('Prizes')}</p>
        <CardTooltip
          text={t('Anything you win from Tournaments will be earned here')}
        />
      </div>

      <div
        className={`rounded-md relative text-center py-2 border text-accent border-accent px-2 w-full
        `}
      >
        <p className={`text-md font-semibold text-accent}`}>{balance} $</p>
      </div>
      {notUSD ? (
        <div className="bg-jacarta-100 text-black py-1 px-2 flex-center rounded-full self-end -mt-0.5 w-full">
          <p className="text-sm font-semibold tracking-tight">
            ~ <CurrencyField amount={balance} />
          </p>
        </div>
      ) : null}
    </div>
  );
};
