import { useAppSelector } from '@/redux/counterSlice';
import { useLiveStore } from '@/store/useLiveStore';
import { LiveTournamentDetails, Tournament } from '@/types/types';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '@/lib/nodeapi';

const useLiveTournament = () => {
  const { data: session, status } = useSession();
  const { tournamentId, bracketId } = useLiveStore();
  const { liveKey } = useAppSelector((state) => state.counter);

  const { data, isLoading } = useQuery<LiveTournamentDetails | undefined>({
    queryKey: [
      'Live Tournament',
      tournamentId,
      bracketId,
      session?.secure,
      liveKey,
    ],
    queryFn: async () => {
      const { data: live } = await nodeAPI(
        'GET',
        session?.secure,
        'play/getLiveTournament/' + tournamentId + '/' + bracketId,
      );

      return live;
    },
    enabled:
      status == 'authenticated' &&
      tournamentId != undefined &&
      bracketId != undefined,
  });

  const { data: game } = useQuery<Tournament | undefined>({
    queryKey: ['Live Game', tournamentId, session?.secure],
    queryFn: async () => {
      const { results } = await nodeAPI(
        'POST',
        session?.secure,
        'play/getPlay',
        {
          playId: tournamentId,
          forLive: true,
        },
      );

      return results[0] ?? null;
    },
    enabled: status == 'authenticated' && !!tournamentId,
  });

  const { data: user } = useQuery<{
    username: string;
    profile_pic: string | undefined;
    pic_border: string | undefined;
    battlecard: string | undefined;
    battlecard_border: string | undefined;
    country_code: string | undefined;
  }>({
    queryKey: ['Current User', session?.secure],
    queryFn: async () => {
      const {
        results: {
          username,
          profile_pic,
          pic_border,
          battlecard,
          battlecard_border,
        },
      } = await nodeAPI('POST', session?.secure, 'profile/getUserProfile', {});

      const { whichCountry } = await nodeAPI(
        'GET',
        session?.secure,
        'auth/hasCountryAndDate',
        {},
      );

      return {
        username,
        profile_pic,
        pic_border,
        battlecard,
        battlecard_border,
        country_code: whichCountry,
      };
    },
    enabled: status == 'authenticated' && !!tournamentId,
  });

  return {
    tournament: data,
    game,
    user,
    isLoading: isLoading,
  };
};

export default useLiveTournament;
