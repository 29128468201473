import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import Next13ProgressBar from 'next13-progressbar';
import { useEffect } from 'react';
import useUpdateBalance from '../hooks/useUpdateBalance';
import useCurrencyRate from '../lib/useCurrencyRate';
import Footer from './footer.tsx';
import Endbar from './layout/sidebar/Endbar';
import Sidebar from './layout/sidebar/Sidebar';
import CreditModal from './modal/CreditModal';
import SearchModal from './modal/SearchModal';
import SuccessClaimModal from './modal/SuccessClaimModal';
import BonusModal from './modal/bonusModal';
import HowToPlayModal from './modal/howToPlayModal';
import LoginModal from './modal/loginModal';
import DepositModal from './modal/payments/DepositModal';
import _1D3Modal from './modal/payments/_1D3';
import SetCountryOfResidenceModal from './modal/setCountryOfResidenceModal';
import DOBModal from './modal/setDOBModal';
import SetPhoneNumberModal from './modal/setPhoneNumberModal';
import SetupAccountModal from './modal/setupAccountModal';
import SuccessModal from './modal/successModal';
import MainNavbar from './navbar/Navbar';

export default function Layout({ children }) {
  const { data: session, status } = useSession();
  const { updateBalance } = useUpdateBalance();

  useQuery({
    queryKey: ['Balance'],
    queryFn: () => updateBalance(),
    enabled: status == 'authenticated',
  });

  useEffect(() => {
    Sentry.setUser(
      status == 'authenticated'
        ? {
            id: session.user.id,
            username: session.user.name,
            email: session.user.email,
          }
        : null,
    );
  }, [session, status]);

  useCurrencyRate();

  return (
    <>
      <Next13ProgressBar
        height="4px"
        color="#8358FF"
        options={{ showSpinner: false }}
        showOnShallow
      />

      <MainNavbar />
      <SuccessModal />
      <LoginModal />
      <SetupAccountModal />
      <SetPhoneNumberModal />
      <SetCountryOfResidenceModal />
      <HowToPlayModal />
      <DOBModal />
      <BonusModal />
      <DepositModal />
      <CreditModal />
      <SuccessClaimModal />
      <SearchModal />
      <_1D3Modal />

      <div className="flex">
        {/* Sidebar */}
        {status === 'authenticated' ? <Sidebar /> : null}

        {/* Main Content */}
        <main className="flex-1 min-w-0">
          {children}
          <Footer />
        </main>

        {status === 'authenticated' ? <Endbar /> : null}
      </div>
    </>
  );
}
