import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  cn,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { OTPInput, REGEXP_ONLY_DIGITS } from 'input-otp';
import { useSession } from 'next-auth/react';
import { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useYup } from '../../hooks/useYup';
import {
  AccountSet,
  setRefetchProfile,
  setupAccountModalHide,
  useAppDispatch,
} from '../../redux/counterSlice';
import { modalStyle } from '../../theme/nextUI';
import nodeAPI from './../../lib/nodeapi';

const SetupAccountModal = () => {
  const { setupAccountModal } = useSelector((state) => state.counter);
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const { t } = useTranslation();
  const countDownRef = useRef();
  const { Yup } = useYup();

  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');
  const [isIncorrectCode, setIsIncorrectCode] = useState(false);
  const [getCountDown, setCountDown] = useState(new Date().getTime() + 10000);

  const {
    mutateAsync: validateDisplayName,
    isPending: isValidatingDisplayName,
  } = useMutation({
    mutationFn: async (username) => {
      try {
        Yup.string()
          .min(5)
          .max(20)
          .matches(
            /^[a-zA-Z0-9]+$/,
            t(
              'Only letters and numbers are allowed. No special characters or spaces.',
            ),
          )
          .validateSync(username);
      } catch (err) {
        return false;
      }

      const { unique } = await nodeAPI(
        'POST',
        session?.secure,
        'user/CheckDisplayName',
        { username },
      );

      return unique;
    },
  });

  const { mutateAsync: validateEmail, isPending: isValidatingEmail } =
    useMutation({
      mutationFn: async (email) => {
        try {
          Yup.string().required().email().validateSync(email);
        } catch (err) {
          return false;
        }

        const { unique } = await nodeAPI(
          'POST',
          session?.secure,
          'user/CheckEmailUnique',
          { email },
        );

        return unique;
      },
    });

  const schema = Yup.object({
    displayName: Yup.string()
      .min(5)
      .max(20)
      .test(
        'validDisplayName',
        t('The display name is already taken'),
        validateDisplayName,
      )
      .label(t('Display name')),
    email: Yup.string()
      .email()
      .required()
      .test('validEmail', t('The email address is already used'), validateEmail)
      .label(t('Email')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isPending: isSendingVerificationEmail } =
    useMutation({
      mutationFn: async ({ displayName: username, email }) => {
        setCountDown(new Date().getTime() + 10000);
        countDownRef.current?.start();

        await nodeAPI('POST', session.secure, 'user/sendEmailVerification', {
          username,
          email,
        });

        setStep(2);
      },
    });

  const { mutate: sendConfirmationEmail } = useMutation({
    mutationFn: async () => {
      setCountDown(new Date().getTime() + 10000);
      countDownRef.current?.start();

      const username = getValues('displayName') || '';
      const email = getValues('email') || '';

      await nodeAPI('POST', session.secure, 'user/sendEmailVerification', {
        username,
        email,
      });
    },
  });

  const {
    mutate: validateSecurityCode,
    isPending: isValidatingCode,
    data: securityCodeMessage,
  } = useMutation({
    mutationFn: async () => {
      if (code.length < 6) return;

      const { status, verified } = await nodeAPI(
        'POST',
        session.secure,
        'user/validateVerificationByCode',
        { security_code: code },
      );

      if (!status) {
        setIsIncorrectCode(true);
        setTimeout(() => {
          setIsIncorrectCode(false);
        }, 2500);

        return;
      }

      setStep(3);
      dispatch(AccountSet());
      dispatch(setRefetchProfile());
      return status && verified
        ? t('Your account has already been verified')
        : t('Email has been successfully verified');
    },
  });

  const renderer = ({ seconds, completed }) => {
    if (completed)
      return (
        <Link className="cursor-pointer" onPress={sendConfirmationEmail}>
          {t('Resend Code')}
        </Link>
      );

    return (
      <p>
        {t('Please wait')}
        <span className="ordinal slashed-zero tabular-nums ">
          {` ${seconds.toString().padStart(2, '0')} `}
        </span>
        {t('seconds before requesting another code.')}
      </p>
    );
  };

  async function closeModalReset() {
    setCode('');
    setStep(1);
    reset({ displayName: '', email: '' });
    dispatch(setupAccountModalHide());
  }

  return (
    <Modal
      isOpen={setupAccountModal}
      onClose={closeModalReset}
      classNames={modalStyle}
    >
      <ModalContent
        as="form"
        onSubmit={handleSubmit(onSubmit, (e) => console.log(e))}
      >
        <ModalHeader>
          <h5 className="modal-title">{t('verify_account')}</h5>
        </ModalHeader>
        <ModalBody className="gap-3">
          {step == 1 && (
            <>
              <div className="flex flex-col gap-1">
                <label className="font-display required" htmlFor="displayName">
                  {t('Display name')}
                </label>
                <div className="relative">
                  <input
                    className="border-2 dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-2 hover:ring-2 dark:text-white px-3 pe-10"
                    type="text"
                    id="displayName"
                    name="displayName"
                    placeholder={t('display_name_placeholder')}
                    {...register('displayName')}
                  />

                  {isValidatingDisplayName && (
                    <Spinner
                      className="absolute end-3.5 top-1/2 -translate-y-1/2"
                      classNames={{ wrapper: '!w-4 !h-4' }}
                    />
                  )}
                </div>
                <p className="text-sm text-red-500">
                  <ErrorMessage errors={errors} name="displayName" />
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <label className="font-display required" htmlFor="email">
                  {t('Email')}
                </label>
                <div className="relative">
                  <input
                    className="border-2 dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-2 hover:ring-2 dark:text-white px-3 pe-10"
                    type="text"
                    id="email"
                    name="email"
                    placeholder={t('email_address_placeholder')}
                    {...register('email')}
                  />
                  {isValidatingEmail && (
                    <Spinner
                      className="absolute end-3.5 top-1/2 -translate-y-1/2"
                      classNames={{ wrapper: '!w-4 !h-4' }}
                    />
                  )}
                </div>
                <p className="text-sm text-red-500">
                  <ErrorMessage errors={errors} name="email" />
                </p>
              </div>
            </>
          )}

          {step == 2 && (
            <div>
              <p className="mb-5">
                {t(`We've sent a security code to`)}
                <b>{` ${getValues('email') || ''}, `}</b>
                {t(`Please enter it below to complete your account setup.`)}
              </p>

              <div className="flex flex-col items-center justify-center gap-2">
                <OTPInput
                  value={code}
                  onChange={(v) => setCode(v)}
                  maxLength={6}
                  pattern={REGEXP_ONLY_DIGITS}
                  className="!w-full"
                  textAlign="center"
                  inputMode="numeric"
                  render={({ slots }) => (
                    <div className="flex items-center justify-center" dir="ltr">
                      {slots.slice(0, 6).map(({ isActive, char }, idx) => (
                        <div
                          key={idx}
                          className={cn(
                            'relative w-10 h-14 text-[2rem]',
                            'flex items-center justify-center',
                            'transition-all duration-300',
                            'border-jacarta-200 dark:border-jacarta-600 border-y border-e first:border-s first:rounded-s-md last:rounded-e-md',
                            'group-hover:border-ring-accent group-focus-within:border-ring-accent ring-inset',
                            { 'ring-2 ring-accent': isActive },
                            {
                              'border-red-500 ring-red-500 bg-red-50/75':
                                isIncorrectCode,
                            },
                          )}
                        >
                          {char !== null && <div>{char}</div>}
                        </div>
                      ))}
                    </div>
                  )}
                  onComplete={(code) => {
                    validateSecurityCode(code);
                  }}
                />

                <Countdown
                  key={getCountDown}
                  innerRef={countDownRef}
                  date={getCountDown}
                  renderer={renderer}
                />
              </div>
            </div>
          )}

          {step == 3 && (
            <div className="flex flex-col items-center justify-center gap-2">
              <Image
                src="/images/payments/status/success.png"
                width={120}
                alt="Status"
              />
              <h2 className="font-display text-lg">{t('Email Verified')}</h2>
              <p className="text-sm">{securityCodeMessage}</p>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="items-center justify-center">
          {step == 1 && (
            <Button
              type="submit"
              className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
              isLoading={isSendingVerificationEmail}
              isDisabled={isValidatingDisplayName || isValidatingEmail}
            >
              {t('Continue')}
            </Button>
          )}

          {step == 2 && (
            <>
              <Button
                variant="light"
                className="font-semibold rounded-full"
                onPress={() => setStep(1)}
              >
                {t('Back')}
              </Button>
              <Button
                type="button"
                className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
                onPress={validateSecurityCode}
                isLoading={isValidatingCode}
                isDisabled={code.length < 6}
              >
                {t('Verify')}
              </Button>
            </>
          )}

          {step == 3 && (
            <>
              <Button
                type="button"
                className="bg-accent font-semibold text-white rounded-full disabled:bg-disabled disabled:text-black"
                onPress={closeModalReset}
                isLoading={isValidatingCode}
              >
                {t('Close')}
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SetupAccountModal;
