import { useQuery } from '@tanstack/react-query';
import cookies from 'js-cookie';
import { useCurrencyStore } from '../store/useCurrencyStore';
import nodeAPI from './nodeapi';

const useCurrencyRate = async () => {
  const setLatestRate = useCurrencyStore((state) => state.setLatestRate);
  const { data } = useQuery({
    queryKey: ['getAllCurrencies'],
    queryFn: async () => await nodeAPI('GET', '', 'extra/getAllCurrencies', {}),
  });

  const currency = data?.results?.find(
    ({ currency_code }) => currency_code == (cookies?.get('currency') || 'USD'),
  );
  if (currency) setLatestRate(currency.rate, currency.digit);
};

export default useCurrencyRate;
