import React from 'react';
import { ToggleThemeIcon } from './navIcons';
import { Button } from '@nextui-org/react';
import { withTranslation } from 'next-i18next';
import { useTheme } from 'next-themes';

function ToggleThemeButton() {
  const { theme, setTheme } = useTheme();

  const toggleTheme = () => {
    if (theme == 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  return (
    <Button
      radius="full"
      isIconOnly
      variant="faded"
      className={'menu-icon-button'}
      aria-label="theme-toggle-button"
      onClick={toggleTheme}
      size="sm"
    >
      <ToggleThemeIcon />
    </Button>
  );
}

export default withTranslation()(ToggleThemeButton);
