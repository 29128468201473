import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';

function useCountries() {
  const { data: session, status } = useSession();

  const { data, isLoading: isLoadingCountries } = useQuery({
    queryKey: ['Countries'],
    queryFn: async () => {
      const { results } = await nodeAPI(
        'GET',
        session?.secure,
        'extra/getAllAllowedCountries',
        {},
      );
      return results;
    },
    enabled: status == 'authenticated',
  });

  return { countries: data, isLoadingCountries };
}

export default useCountries;
