import { cn } from '@nextui-org/react';
import { t } from 'i18next';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useRef } from 'react';
import PhoneInputX from 'react-phone-input-2';

interface Props {
  phone: string;
  phoneCountry: string;
  setPhone: (phone: string) => void;
  setPhoneCountry: (phoneCountry: CountryCode) => void;
  placeholder?: string;
  label?: string;
}

const PhoneInput = ({
  phone,
  phoneCountry,
  setPhone,
  setPhoneCountry,
  placeholder = 'Enter mobile number',
  label = 'Enter mobile number',
}: Props) => {
  const phoneRef = useRef<any>();

  useEffect(() => {
    if (!phoneRef.current || !phoneRef.current?.state.selectedCountry.iso2)
      return;
    const pc = phoneRef.current?.state.selectedCountry.iso2.toUpperCase();
    setPhoneCountry(pc);
  }, [phone, setPhoneCountry]);

  return (
    <>
      <label
        className="text-start text-foreground text-sm mb-1"
        htmlFor="phoneNumber"
      >
        {t(label)}
      </label>
      <PhoneInputX
        // @ts-ignore
        ref={phoneRef as any}
        country={phoneCountry ?? 'jo'}
        excludeCountries={['il']}
        placeholder={`${t(placeholder)}`}
        containerClass={cn('w-full force-ltr !z-20')}
        inputClass="!rounded-xl shadow-md !border-2 focus:!border-black focus:!ring-0 !py-1.5 hover:!border-black/50 py-2 min-w-full dark:!bg-jacarta-700 dark:!text-white dark:!border-jacarta-500"
        dropdownClass="!fixed dark:!bg-jacarta-700 [&>li:hover]:dark:!bg-jacarta-600 [&>li]:transition-colors"
        inputProps={{ id: 'phoneNumber' }}
        value={phone}
        onChange={setPhone}
      />
    </>
  );
};
export default PhoneInput;
