import { useState, useEffect } from 'react';

const useBreakpoint = (breakpoint) => {
  const [isMatch, setIsMatch] = useState(false);
  const [isLess, setIsLess] = useState(false);
  // const { width } = useWindowSize();
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      setIsMatch(width >= screensInNum[breakpoint]);
      setIsLess(width <= screensInNum[breakpoint]);
    };

    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return { isMatch, isLess };
};

export default useBreakpoint;

export const screens = {
  xs: '420px',
  sm: '575px',
  md: '768px',
  lg: '1025px',
  xl: '1202px',
  '2xl': '1436px',
};

export const screensInNum = {
  sm: 575,
  md: 768,
  lg: 1025,
  xl: 1202,
  '2xl': 1436,
};
