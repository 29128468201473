import { modalStyle, primaryButton } from '@/theme/nextUI';
import { useToBlob } from '@hugocxl/react-to-image';
import {
  Button,
  cn,
  Image,
  Modal,
  ModalBody,
  ModalContent,
} from '@nextui-org/react';
import { ReactNode, useState } from 'react';
import { MdShare } from 'react-icons/md';
import Flag from 'react-world-flags';

interface WCI {
  user?: {
    id: string;
    username: string;
    profile_pic: string | undefined;
    pic_border: string | undefined;
    battlecard: string | undefined;
    battlecard_border: string | undefined;
    country_code: string | undefined;
  };
  isSmall?: boolean;
  children?: ReactNode;
}

const WinnerCard = ({ children, user, isSmall = false }: WCI) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  const [_, convert, ref] = useToBlob<HTMLDivElement>({
    onSuccess: (data) => {
      if (!data) return;
      setImage(URL.createObjectURL(data));
      setOpen(true);
    },
    filter({ tagName }) {
      return tagName != 'BUTTON';
    },
  });

  const hideModal = () => {
    setOpen(false);
  };

  if (!user) return null;

  return (
    <div className="mx-auto w-fit">
      <div
        className="relative max-w-sm flex flex-col items-center px-8 py-3 text-black"
        ref={ref}
      >
        <div
          className={cn(
            'relative overflow-hidden rounded-lg mx-auto',
            isSmall ? 'max-w-xs' : 'max-w-sm',
          )}
        >
          <Image
            src={user?.battlecard ?? '/images/profile/default-battle-card.jpg'}
            className="w-full"
            alt="Battle Card"
          />
          <Image
            src={
              user?.battlecard_border ??
              '/images/profile/default-battle-card-border.png'
            }
            className="rounded-lg w-full absolute top-0 left-0 z-10"
            alt="Battle Card Border"
            removeWrapper
          />
        </div>
        <div className="absolute bottom-6 z-20 w-[calc(100%-1.5rem)] space-y-2">
          <div className="flex items-center justify-between gap-2 w-full border-2 border-accent bg-jacarta-100/80 backdrop-blur-sm rounded-lg p-1">
            <div
              className={cn(
                'relative rounded-lg overflow-hidden',
                isSmall ? 'w-14' : 'w-20',
              )}
            >
              <Image
                src={
                  user?.profile_pic ??
                  '/images/profile/default-profile-image.jpg'
                }
                className="w-full"
                alt="Profile Pic"
              />
              <Image
                src={
                  user?.pic_border ??
                  '/images/profile/default-profile-border.png'
                }
                className="rounded-lg w-full absolute top-0 left-0 z-10"
                alt="Profile Pic Border"
                removeWrapper
              />
            </div>
            <h2
              className={cn(
                'flex-1 font-semibold',
                isSmall ? 'text-lg' : 'text-xl',
              )}
            >
              {user?.username}
            </h2>
            <Flag
              className={isSmall ? 'size-9' : 'size-14'}
              code={user?.country_code}
            />
          </div>
          <div className="flex flex-col items-center gap-2 w-full border-2 border-accent bg-jacarta-100/80 backdrop-blur-sm rounded-lg p-1 relative mb-2">
            {children}

            <div className="absolute top-1 bottom-1 end-0 flex flex-col justify-between items-center">
              <Image
                src="/images/icon_logo.png"
                alt="GamerG"
                className={cn(isSmall ? 'size-10' : 'size-14')}
                removeWrapper
              />
              <Button
                isIconOnly
                radius="full"
                size={isSmall ? 'sm' : 'md'}
                className="h-auto w-auto min-w-[auto] bg-accent"
                onPress={convert}
                data-ignore
              >
                <MdShare
                  size={isSmall ? 26 : 30}
                  color="#fff"
                  className="p-1.5"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={open}
        size="sm"
        onClose={hideModal}
        classNames={modalStyle}
      >
        <ModalContent>
          <ModalBody className="flex flex-col items-center gap-2">
            {image && (
              <>
                <Image src={image} alt="Winner Image" className="w-full" />

                <a href={image} download>
                  <Button className={primaryButton} radius="full">
                    Download
                  </Button>
                </a>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default WinnerCard;
