import Image from 'next/image';
import React from 'react';

const Gem = ({ text, size = 24 }) => {
  return (
    <span className="inline-flex items-center text-purple">
      {text}
      <Image src="/images/gem/gem.png" width={size} height={size} alt="Gem" />
    </span>
  );
};

export default Gem;
