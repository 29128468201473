import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import useTracker, { TrakerTypes } from '../../hooks/useTraker';
import { useControlPanelStore } from '../../store/useControlPanelStore';
import CurrencySwitcher from './CurrencySwitcher';
import LangSwitcher from './LangSwitcher';
import MenuButtonTrigger from './MenuButtonTrigger';
import SocialMediaIcons from '@/components/socialMediaIcons';

export default function UnAuthMobileMenu() {
  const { isOpen, setIsOpen } = useControlPanelStore();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated' && !isData?.banned;

  return (
    <Popover isOpen={isOpen} onOpenChange={setIsOpen} shouldBlockScroll>
      <PopoverTrigger>
        {isAuthenticated ? null : <MenuButtonTrigger isOpen={isOpen} />}
      </PopoverTrigger>
      <PopoverContent
        aria-label="Static Actions"
        className={'menu-gradient min-w-[90vw] md:min-w-[350px]  '}
      >
        <MobileMenu setIsOpen={setIsOpen} />
      </PopoverContent>
    </Popover>
  );
}

const MobileMenu = ({ setIsOpen }) => {
  const router = useRouter();
  const { asPath, push } = router;

  const trackTournamentPage = useTracker({
    key: TrakerTypes.TournamentPageVisited,
  });

  return (
    <Card shadow="none" className="w-full border-none bg-transparent">
      <CardHeader className="justify-between flex-col lg:hidden gap-2">
        <div className="flex-center gap-4 w-full">
          <CurrencySwitcher />
          <LangSwitcher />
        </div>
      </CardHeader>
      <CardFooter
        className={`border-t-1 border-jacarta-300 flex items-start lg:items-center flex-col gap-1 $`}
      >
        <Button
          variant="light"
          className={` ${menuButtonClassnames} ${asPath === '/' ? 'text-accent' : ''}`}
          onClick={() => {
            localStorage.setItem('navItemValue', 1);
            setIsOpen(false);
            push('/');
          }}
        >
          {t('Home')}
        </Button>

        <Button
          variant="light"
          className={` ${menuButtonClassnames} ${asPath === '/tournaments' ? 'text-accent' : ''} `}
          onClick={() => {
            trackTournamentPage();
            setIsOpen(false);
            push('/tournaments');
          }}
        >
          {t('Tournaments')}
        </Button>

        <Button
          variant="light"
          className={`${menuButtonClassnames} ${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          onClick={() => {
            setIsOpen(false);
            push('/leaderboard');
          }}
        >
          {t('Leaderboard')}
        </Button>
        <Button
          variant="light"
          className={`${menuButtonClassnames} ${asPath === '/contact' ? 'text-accent' : ''}`}
          onClick={() => {
            setIsOpen(false);
            push('/contact');
          }}
        >
          {t('Help_Center')}
        </Button>

        <SocialMediaIcons className="mt-3 justify-center" />
      </CardFooter>
    </Card>
  );
};

const menuButtonClassnames = `text-jacarta-700 dark:text-jacarta-50 font-semibold hover:bg-transparent dark:hover:bg-transparent px-2 text-start inline lg:hidden data-[hover=true]:bg-transparent h-7`;
