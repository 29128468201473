import useUpdateBalance from '@/hooks/useUpdateBalance';
import nodeAPI from '@/lib/nodeapi';
import { modalStyle } from '@/theme/nextUI';
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import cookies from 'js-cookie';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

const BonusModal = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { data: session, status } = useSession();
  const { updateBalance } = useUpdateBalance();

  const { data: bonusEligible, refetch } = useQuery({
    queryKey: ['Bonus Eligble', session?.secure],
    queryFn: async () => {
      const bonusClaimed = cookies.get('bonusClaimed') == 'yes';
      if (bonusClaimed) return false;

      const { bonusEligible } = await nodeAPI(
        'POST',
        session?.secure,
        'finances/bonusEligble',
      );

      if (bonusEligible) return true;

      cookies.set('bonusClaimed', 'yes', { expires: 365 });
      return false;
    },
    initialData: false,
    enabled: status == 'authenticated',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await nodeAPI('POST', session?.secure, 'finances/bonusClaim');
      cookies.set('bonusClaimed', 'yes', { expires: 365 });

      await refetch();
      updateBalance();
    },
  });

  return (
    <Modal
      isOpen={bonusEligible}
      size="sm"
      classNames={modalStyle}
      hideCloseButton
    >
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
          <h5 className="modal-title">{t('Sign up Bonus')}</h5>
        </ModalHeader>
        <ModalBody className="flex flex-col items-center gap-2">
          <Image
            src={`/images/bonus/${router.locale == 'en-US' ? 'en.png' : 'ar.png'}`}
            alt="bonus"
            className="rounded-lg w-full"
            disableSkeleton
          />

          <div className="flex flex-col items-center *:text-center gap-2 pt-4 pb-1">
            <h6 className="font-display">{t('Welcome to GamerG')}</h6>
            <p>{t(`Get your FREE 15 Credits!`)}</p>
            <p>
              {t(
                `You can use your Credits to join Tournaments with Big Prizes`,
              )}
            </p>

            <Button
              className="bg-accent text-white font-semibold mt-1"
              radius="full"
              size="lg"
              onPress={() => mutate()}
              isLoading={isPending}
            >
              {t('Claim Free Credits')}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BonusModal;
