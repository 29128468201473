import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import nodeAPI from '../lib/nodeapi';
import { useAppSelector } from '../redux/counterSlice';

const useUserProfile = () => {
  const { data: session, status } = useSession();
  const { refetchProfile } = useAppSelector((state) => state.counter);

  const userProfile = useQuery({
    queryKey: ['User', session, refetchProfile],
    queryFn: async () => {
      const results = await nodeAPI(
        'POST',
        session?.secure,
        'profile/getUserProfile',
        {},
      );

      return results?.results;
    },
    enabled: status == 'authenticated',
  });

  return userProfile;
};

export default useUserProfile;
