import { Badge, Button } from '@nextui-org/react';
import React from 'react';
import { ShareIcon } from './navIcons';
import useTracker, { TrakerTypes } from '../../hooks/useTraker';

export default function GoToGPartnerButton({ isData }) {
  const GoToGPartner = useTracker({ key: TrakerTypes.gPartnerIcon, isData });

  return (
    <Badge
      content=""
      color="danger"
      placement="top-left"
      showOutline={false}
      className="top-[15%] left-[13%]"
    >
      <Button
        size="sm"
        onClick={GoToGPartner}
        isIconOnly
        variant="faded"
        radius="full"
        className={'menu-icon-button'} // in global css
      >
        <ShareIcon />
      </Button>
    </Badge>
  );
}
