import { Button, Link } from '@nextui-org/react';
import { t } from 'i18next';
import React from 'react';
import { SiDiscord } from 'react-icons/si';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function DiscordButton() {
  // const { isLess: isLessXl } = useBreakpoint('xl');
  return (
    <Button
      size="md"
      className="bg-[#7289da] text-white rounded-full font-semibold "
      as={Link}
      target="_blank"
      href="https://discord.gg/uSJkN95JxW"
      startContent={<SiDiscord size={18} />}
      // isIconOnly={isLessXl ? true : false}
    >
      {t('Join our Discord')}
    </Button>
  );
}
