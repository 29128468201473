import React from 'react';
import { useQuery } from '@tanstack/react-query';
import nodeAPI from '../lib/nodeapi';
import { useSession } from 'next-auth/react';
const useAchievementsRanking = () => {
  const { data: session, status } = useSession();

  const userRank = useQuery({
    queryKey: ['user-rank', { status }],
    queryFn: async () => {
      const getRank = await nodeAPI(
        'POST',
        session.secure,
        'play/achievementsRanking',
        {},
      );
      return getRank;
    },
    enabled: status == 'authenticated',
  });
  return userRank;
};

export default useAchievementsRanking;
