import { Template, TournamentType } from '@/types/types';
import { Image, Tooltip } from '@nextui-org/react';
import cookies from 'js-cookie';
import { useRouter } from 'next/router';
import CurrencyField from '../CurrencyField';

const TournamentHeader = ({
  template,
  entry_fee,
  tournamentType,
}: {
  template: Template;
  entry_fee?: number;
  tournamentType?: TournamentType;
}) => {
  const router = useRouter();

  return (
    <div className="flex items-center justify-between gap-2 text-white flex-1 min-w-0 w-full">
      <div className="flex items-center gap-2 w-full">
        <Tooltip
          content={
            router.locale === 'en-US'
              ? template?.game_details?.game_name_en
              : template?.game_details?.game_name_ar
          }
          color="primary"
        >
          <Image
            src={template?.game_details?.game_mode_image}
            width={20}
            height={20}
            className="rounded-none"
            alt="Game Mode"
            disableSkeleton
          />
        </Tooltip>
        <span className="font-display text-lg font-medium text-start flex-1 truncate">
          {router.locale === 'en-US' ? template.name : template.name_ar}
        </span>
      </div>
      {tournamentType != 'credits' && entry_fee ? (
        cookies.get('currency') &&
        cookies.get('currency') !== 'USD' && (
          <div className="items-center bg-jacarta-100 text-black rounded-full px-2.5 py-1 text-xs font-medium whitespace-nowrap">
            ~<CurrencyField amount={entry_fee} />
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default TournamentHeader;
