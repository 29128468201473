import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const useLivePhase = (liveTimes: { 1: Date; 2: Date; 3: Date }) => {
  const [currPhase, setCurrPhase] = useState<number>(1);

  const adjustPhase = () => {
    setCurrPhase(() => {
      if (!liveTimes) return 1;

      const now = dayjs();

      if (dayjs(liveTimes[1]).diff(now) > 0) return 1;
      if (dayjs(liveTimes[2]).diff(now) > 0) return 2;
      return 3;
    });
  };

  useEffect(adjustPhase, [liveTimes]);

  return {
    currPhase,
    adjustPhase,
    currRemainingTime: liveTimes?.[`${currPhase}`],
  };
};

export default useLivePhase;
