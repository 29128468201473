import { create } from 'zustand';

interface BalanceState {
  balance: {
    depositBalance: number;
    prizeUserBalance: number;
    creditBalance: number;
  };
  setBalance: (balance: BalanceState['balance']) => void;
}

export const useBalanceStore = create<BalanceState>()((set) => ({
  balance: {
    depositBalance: 0,
    prizeUserBalance: 0,
    creditBalance: 0,
  },
  setBalance: (balance) => set(() => ({ balance })),
}));
