import nodeAPI from '@/lib/nodeapi';
import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import useUpdateBalance from '../useUpdateBalance';

function useClaimDailyRewards({
  successCallback,
}: {
  successCallback?: () => void;
}) {
  const { data: session } = useSession();
  const { updateBalance } = useUpdateBalance();
  const profileDesign = useMutation({
    mutationFn: async () => {
      const response = await nodeAPI(
        'POST',
        session?.secure,
        'dailyReward/claim',
      );
      return response;
    },
    onSuccess: async () => {
      updateBalance();
      if (successCallback) successCallback();
    },
  });

  return profileDesign;
}

export default useClaimDailyRewards;
