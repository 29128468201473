import useDebounceSearch from '@/hooks/useDebounceSearch';
import { setSearchModal, useAppDispatch } from '@/redux/counterSlice';
import { Input } from '@nextui-org/react';
import { t } from 'i18next';
import { IoMdSearch } from 'react-icons/io';

interface Props {
  type?: 'modal' | 'nav';
  // searchText?: string;
  handleSearchChange?: (e: any) => void;
}
function Searchbar({ type, handleSearchChange }: Props) {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(setSearchModal(true));
  };

  return (
    <Input
      isClearable
      type="search"
      radius="full"
      size="sm"
      className=""
      onClick={type !== 'modal' ? handleOpenModal : undefined}
      classNames={{
        input: [
          'min-w-[150px] 2xl:min-w-[270px]',
          '',
          'text-jacarta-700 dark:text-jacarta-200',
          'placeholder:text-default-700/50 dark:placeholder:text-white/60',
          'focus:ring-transparent border-none dark:focus:ring-transparent  px-0 shadow-none',
        ],
        innerWrapper: ['bg-transparent shadow-none', ''],
        inputWrapper: [
          'shadow-none  data-[focus=true]:dark:bg-jacarta-800 data-[hover=true]:bg-jacarta-200',
          type === 'modal'
            ? 'dark:bg-jacarta-700 bg-white data-[focus=true]:dark:bg-jacarta-800 data-[hover=true]:bg-jacarta-200'
            : 'dark:bg-transparent bg-transparent data-[hover=true]:dark:bg-transparent data-[focus=true]:dark:bg-transparent data-[hover=true]:bg-transparent',
        ],
        base: ['border-2 border-accent rounded-full '],
      }}
      placeholder={t('search') + '...'}
      // value={searchText}
      onChange={handleSearchChange}
      startContent={
        <IoMdSearch
          fontSize={'22px'}
          className="text-accent"
          onClick={type !== 'modal' ? handleOpenModal : undefined}
        />
      }
    />
  );
}

export default Searchbar;
