import Head from 'next/head';

const Meta = ({
  title = 'GamerG.gg',
  keyword = '',
  desc = 'GamerG Esports Tournaments Platform',
}) => {
  return (
    <Head>
      <title>{title}</title>

      <link rel="icon" href="/favicon.png" />
      <meta name="description" content={desc} />
      <meta name="keyword" content={keyword} />
    </Head>
  );
};

export default Meta;
