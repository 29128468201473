import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  cn,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useRouter } from 'next/router';

interface DayCardProps {
  day: number;
  amount: number;
  status: boolean;
  currentDay: number;
  className?: string;
}

export const DayCard = ({
  day,
  amount,
  status,
  currentDay,
  className,
}: DayCardProps) => {
  const isAbleToClaim = day === currentDay && status;
  const isClaimed = (day === currentDay && !status) || day < currentDay;
  return (
    <Card
      shadow="none"
      className={cn(
        `w-full text-white font-bold ${isAbleToClaim ? 'bg-accent' : 'bg-[#a8a8a8]'}`,
        className,
      )}
      radius="sm"
    >
      <CardHeader className="justify-center p-0 text-sm">
        {t('Day')} {day}
      </CardHeader>
      <CardBody className={`p-0 relative`}>
        <div className={`z-10 ${!isAbleToClaim ? 'filter grayscale' : ''}`}>
          <Image
            src={`/images/gg-credit-modal/${getCreditImage(amount)}.webp`}
            alt="credit image"
            radius="none"
          />
        </div>
        {isClaimed ? (
          <div className={`top-0 left-0 absolute z-50 `}>
            <Image
              src="/images/gg-credit-modal/checked.webp"
              alt="credit image"
              radius="none"
            />
          </div>
        ) : null}
      </CardBody>
      <CardFooter className="justify-center text-lg leading-none p-1">
        X {amount}
      </CardFooter>
    </Card>
  );
};

export const DayLongCard = ({
  day,
  amount,
  status,
  currentDay,
  className,
}: DayCardProps) => {
  const isAbleToClaim = day === currentDay && status;
  const isClaimed = day === currentDay && !status;
  const { locale } = useRouter();
  return (
    <Card
      shadow="none"
      className={cn(
        `w-full font-bold border-2 ${isAbleToClaim ? 'border-accent  text-accent' : 'border-[#a8a8a8] text-[#a8a8a8]'} text-xl`,
        className,
      )}
      radius="sm"
    >
      <CardBody className={`p-0 relative`}>
        <div className="absolute top-2 left-3 z-50 ">
          {t('Day')} {day}
        </div>
        <div className="absolute bottom-2 right-3 z-50 ">X {amount}</div>
        <div
          className={`z-10 w-full ${!isAbleToClaim ? 'filter grayscale' : ''}`}
        >
          <Image
            src={`/images/gg-credit-modal/${getCreditImage(amount)}.webp`}
            alt="credit image"
            radius="none"
            className="w-full"
            style={{
              maxWidth: '100%',
            }}
            classNames={{
              img: `w-full`,
              wrapper: `w-full !max-w-full`,
            }}
          />
        </div>
        {isClaimed ? (
          <div className={`top-1 absolute z-50 w-[30%] start-[35%]`}>
            <Image
              src="/images/gg-credit-modal/checked.webp"
              alt="credit image"
              radius="none"
            />
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

const getCreditImage = (amount: number) => {
  switch (amount) {
    case 5:
      return '1';
    case 10:
      return '2';
    case 15:
      return '5';
    default:
      return '1';
  }
};
