import { Button } from '@nextui-org/react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

// interface ListItem {
//   href: string;
//   label: string;
//   action: () => void;

// }

// interface DropDownHoverProps {
//   trigger: JSX.Element;
//   listData: ListItem[];
// }

export default function DropDownHover({ trigger, listData, children }) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const delay = 200;

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => setIsOpen(false), delay);
    setTimeoutId(id);
  };

  return (
    <Dropdown
      isOpen={isOpen}
      classNames={{
        content: 'menu-gradient',
      }}
    >
      <DropdownTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <Button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        </Button> */}
        {children}
      </DropdownTrigger>
      <DropdownMenu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="lang-switcher-menu"
        variant="flat"
        itemClasses={{
          base: [
            'text-jacarta-800 dark:text-jacarta-50',
            'data-[hover=true]:bg-jacarta-100',
            'dark:data-[hover=true]:bg-jacarta-700',
          ],
        }}
      >
        {listData.map((element, i) => (
          <DropdownItem key={i} onClick={() => router.push(element.href)}>
            {element.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
