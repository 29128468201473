import { useQuery } from '@tanstack/react-query';
import nodeAPI from '@/lib/nodeapi';
import { LeaderboardUser } from '@/types/types';
import { useSession } from 'next-auth/react';

const useLeaderboard = (game: string) => {
  const { data: session } = useSession();

  const leaderboard = useQuery<{
    results: LeaderboardUser[];
    season: number;
    userScore: LeaderboardUser & {
      gameId: string;
      pic_border: string;
      battlecard: string;
      battlecard_border: string;
    };
  }>({
    queryKey: ['Leaderboard', game, session?.secure],
    queryFn: async () => {
      const data = await nodeAPI(
        'get',
        session?.secure,
        `play/leaderboard?gameId=${game}`,
      );
      return data;
    },
  });

  return leaderboard;
};

export default useLeaderboard;
