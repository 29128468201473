export const GAMES = [
  {
    value: '1',
    label: 'League of Legends',
    image: '/images/gamelogo/new/League of Legends.jpg',
  },
  {
    value: '2',
    label: 'Valorant',
    image: '/images/gamelogo/new/Valorant.jpg',
  },
  {
    value: '5',
    label: 'Fortnite',
    image: '/images/gamelogo/new/Fortnite.jpg',
  },
  {
    value: '3',
    label: 'DotA',
    image: '/images/gamelogo/new/Dota.jpg',
  },
  {
    value: '4',
    label: 'Brawl Stars',
    image: '/images/gamelogo/new/Brawl Stars.jpg',
  },
  {
    value: '6',
    label: 'Apex Legends',
    image: '/images/gamelogo/new/Apex Legends.jpg',
  },
  {
    value: '7',
    label: 'Counter Strike',
    image: '/images/gamelogo/new/Counter Strike.jpg',
  },
  {
    value: '8',
    label: 'FreeFire',
    image: '/images/gamelogo/new/FreeFire.jpg',
  },
  {
    value: '9',
    label: 'PUBG',
    image: '/images/gamelogo/new/PUBG.jpg',
  },
  {
    value: '10',
    label: 'Clash Royale',
    image: '/images/gamelogo/new/Clash Royale.jpg',
  },
];
