import { parseAbsoluteToLocal } from '@internationalized/date';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useSelector } from 'react-redux';
import nodeAPI from '../lib/nodeapi';

const useUserCountryAndDate = () => {
  const { data: session, status } = useSession();
  const { checkPhone, checkDOB, checkCOR, checkAccountSet } = useSelector(
    (state) => state.counter,
  );
  const { data, isLoading: isLoadingcountryAndDate } = useQuery({
    queryKey: [
      'user-country-date',
      { session, checkAccountSet, checkCOR, checkDOB, checkPhone },
    ],
    queryFn: async () =>
      await nodeAPI('GET', session?.secure, 'auth/hasCountryAndDate', {}),
    enabled: status == 'authenticated',
  });

  const defaultCountryAndDate =
    !isLoadingcountryAndDate && data?.theDOB
      ? parseAbsoluteToLocal(data?.theDOB)
      : '';

  return {
    countryAndDate: data,
    isLoadingcountryAndDate,
    defaultCountryAndDate: defaultCountryAndDate,
    phoneCode: data?.phoneCode,
    phone: data?.thePhone,
  };
};

export default useUserCountryAndDate;
