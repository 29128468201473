import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useSelector } from 'react-redux';
import nodeAPI from '../lib/nodeapi';

const useProfileName = () => {
  const { checkAccountSet } = useSelector((state) => state.counter);
  const { data: session, status } = useSession();

  const { data: profileName } = useQuery({
    queryKey: ['Profile Name', { checkAccountSet }],
    queryFn: async () => {
      const { name } = await nodeAPI(
        'POST',
        session?.secure,
        'profile/getProfileName',
      );
      return name;
    },
    enabled: status == 'authenticated',
  });

  return { profileName };
};

export default useProfileName;
